import React from 'react';
import IconSVG from './IconSVG';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { IApplicationState } from '../store';
import { RoutesConfig, Route } from '../config/routes.config';
import ResponsiveMenu from 'react-responsive-navbar';
const BasedGhostLogo = require('../assets/image/Header.png') as string;
const EntvicklerLogo = require('../assets/image/entvickler.png') as string;
const CoffeeCup = require('../assets/image/Coffeecup.png') as string;
const Rocket = require('../assets/image/rocket.png') as string;
const Planet = require('../assets/image/Planet.png') as string;
const Mobile = require('../assets/image/Mobile.png') as string;
const Random = require('../assets/image/Random.png') as string;

const Services = require('../assets/image/Services.png') as string;
const Files = require('../assets/image/Files.png') as string;
const SPOTLIGHT= require('../assets/image/SPOTLIGHT.png') as string;
const Bullhorn=require('../assets/image/Bullhorn.png') as string;
const Paperplane=require('../assets/image/Paperplane.png') as string;
const Box=require('../assets/image/Box.png') as string;

const Horizons=require('../assets/image/horizons.png') as string;
const BluePlanet=require('../assets/image/blueplanet.png') as string;
const EScrappy=require('../assets/image/escrappy.png') as string;
const LAMeera=require('../assets/image/lameera.png') as string;
const Esorr=require('../assets/image/esorr.png') as string;

const COO=require('../assets/image/surajx.png') as string;
const CEO=require('../assets/image/sunil.png') as string;
const Team=require('../assets/image/Team.png') as string;

const chat_app=require('../assets/image/SVG/products/chat_app.svg') as string;
const classified_app=require('../assets/image/SVG/products/classified_app.svg') as string;
const crowdfunding=require('../assets/image/SVG/products/crowdfunding.svg') as string;
const custom_product_design=require('../assets/image/SVG/products/custom_product_design.svg') as string;
const dating_app=require('../assets/image/SVG/products/dating_app.svg') as string;
const fitness_app=require('../assets/image/SVG/products/fitness_app.svg') as string;
const food_delivery=require('../assets/image/SVG/products/food_delivery.svg') as string;
const job_portal=require('../assets/image/SVG/products/job_portal.svg') as string;
const learning_management=require('../assets/image/SVG/products/learning_management.svg') as string;
const multiple_vendors=require('../assets/image/SVG/products/multiple_vendors.svg') as string;
const on_demand_service=require('../assets/image/SVG/products/on_demand_service.svg') as string;
const online_grocery=require('../assets/image/SVG/products/online_grocery.svg') as string;
const real_estate_app=require('../assets/image/SVG/products/real_estate_app.svg') as string;
const ride_sharing=require('../assets/image/SVG/products/ride_sharing.svg') as string;
const salon_spa=require('../assets/image/SVG/products/salon_spa.svg') as string;
const social_media=require('../assets/image/SVG/products/social_media.svg') as string;
const travel_hospitality=require('../assets/image/SVG/products/travel_hospitality.svg') as string;
const travel_tourism=require('../assets/image/SVG/products/travel_tourism.svg') as string;


const Integrity=require("../assets/image/SVG/corevalues/Integrity.svg") as string;
const Candor=require("../assets/image/SVG/corevalues/Candor.svg") as string;
const Service=require("../assets/image/SVG/corevalues/Service.svg") as string;
const Kindness=require("../assets/image/SVG/corevalues/Kindness.svg") as string;
const Competence=require("../assets/image/SVG/corevalues/Competence.svg") as string;
const Growth=require("../assets/image/SVG/corevalues/Growth.svg") as string;

const cms=require("../assets/image/SVG/webapp/cms.svg") as string;
const ecommerce=require("../assets/image/SVG/webapp/ecommerce.svg") as string;
const custom=require("../assets/image/SVG/webapp/custom.svg") as string;
const api=require("../assets/image/SVG/webapp/api.svg") as string;
const migration=require("../assets/image/SVG/webapp/migration.svg") as string;
const issues=require("../assets/image/SVG/webapp/issues.svg") as string;


const ui_ux=require("../assets/image/SVG/mobile/ui_ux.svg") as string;
const business_app=require("../assets/image/SVG/mobile/business_app.svg") as string;
const ar_vr=require("../assets/image/SVG/mobile/ar_vr.svg") as string;
const amp=require("../assets/image/SVG/mobile/amp.svg") as string;
const maintenence=require("../assets/image/SVG/mobile/maintenence.svg") as string;
const android=require("../assets/image/SVG/mobile/android.svg") as string;
const ios=require("../assets/image/SVG/mobile/ios.svg") as string;
const react_native=require("../assets/image/SVG/mobile/react_native.svg") as string;
const flutter=require("../assets/image/SVG/mobile/flutter.svg") as string;
const hybrid=require("../assets/image/SVG/mobile/hybrid.svg") as string;

const angular=require('../assets/image/SVG/webapp/angular.svg') as string;
const aspnet=require('../assets/image/SVG/webapp/dotnet.svg') as string;
const java=require('../assets/image/SVG/webapp/java.svg') as string;
const laravel=require('../assets/image/SVG/webapp/laravel.svg') as string;
const nodejs=require('../assets/image/SVG/webapp/nodejs.svg') as string;
const php=require('../assets/image/SVG/webapp/php.svg') as string;
const python=require('../assets/image/SVG/webapp/python.svg') as string;
const sharepoint=require('../assets/image/SVG/webapp/sharepoint.svg') as string;
const wordpress=require('../assets/image/SVG/webapp/wordpress.svg') as string;
//const aspnet=require('../assets/image/Team.png') as string;

const platform=require("../assets/image/SVG/iot/platform.svg") as string;
const solution=require("../assets/image/SVG/iot/solution.svg") as string;
const integration=require("../assets/image/SVG/iot/integration.svg") as string;
const app=require("../assets/image/SVG/iot/app.svg") as string;

const mql = window.matchMedia(`(min-width: 800px)`);
const BulmaLogo = require('../assets/image/entvickler.png');//require('../../assets/image/Header.png') as string;
const MainContent: React.FC = () => (
<div className='dashboard-wrapper'>
    <div className="fullwidth">
      <div data-ismobile="false" data-is-mesh-layout="false" className="layout-k3sr3k5v-item layout-k3sr3k5v-component style-k5cfurs9" id="c1dmp">
        <div id="c1dmpbg" className="style-k5cfurs9bg"></div>
        <div className="layout-k3sr3k5v-container style-k5cfurs9inlineContent" id="c1dmpinlineContent">
          <section data-is-absolute-layout="false" className="layout-k5cfstk7-item layout-k5cfstk7-component style-k5cfstk74" id="comp-k5cfsti11">
                <div id="comp-k5cfsti11bg" className="style-k5cfstk74bg"></div>
                    <div className="layout-k5cfstk7-container style-k5cfstk74inlineContent" id="comp-k5cfsti11inlineContent">
                    <div data-is-absolute-layout="false" className="layout-kb0izibs-item layout-kb0izibs-component style-kb0izibu1 p-4" id="comp-kb0izi9b">
                        <div id="comp-kb0izi9bbg" className="style-kb0izibu1bg"></div>
                        <div className="layout-kb0izibs-container style-kb0izibu1inlineContent" id="comp-kb0izi9binlineContent">
                        <div className="layout-kaw54442-item layout-kaw54442-component style-kaw54444" id="comp-kaw5442d">
                            <p className="font_4 mobile-set-dream">Teamwork Makes Dreamwork</p>
                        </div>
                        <div className="layout-kb0iyagk-item layout-kb0iyagk-component style-kb0iyagm1" id="comp-kb0iyad0">
                            <h1 className="font_0 adjust">Entvickler provides an effective and joyful way to manage your creative projects</h1>
                        </div>
                        <div id="comp-kaw5dzdi" data-align="center" data-disabled="false" data-margin="0" data-should-use-flex="true" data-width="160" data-height="90" className="layout-kaw5dzf81-item layout-kaw5dzf81-component style-kaw5dzfa1" data-state="desktop shouldUseFlex center"><a href="#comp-kaw5pcz9" target="_self" id="comp-kaw5dzdilink" className="style-kaw5dzfa1link"><span id="comp-kaw5dzdilabel" className="style-kaw5dzfa1label">Get Started</span></a></div>
                        </div>
                    </div>
                    <div className="row-fluid">
                        <div className="row">
                        <img id='login-img' src={BasedGhostLogo} alt='based-ghost-logo' className="fullwidth" />
                    </div> 
                    </div>
                </div>
          </section>
          <section data-is-absolute-layout="false" className="layout-kaw5i53w-item layout-kaw5i53w-component style-kaw5i549" id="company">
                        <div id="comp-kaw5i532bg" className="style-kaw5i549bg"></div>
                        <div className="layout-kaw5i53w-container style-kaw5i549inlineContent" id="comp-kaw5i532inlineContent">
                           <div data-is-absolute-layout="false" className="layout-kaw6b7kx-item layout-kaw6b7kx-component style-kaw6b7ky1" id="comp-kaw6b7k3">
                              <div id="comp-kaw6b7k3bg" className="style-kaw6b7ky1bg"></div>
                              <div className="layout-kaw6b7kx-container style-kaw6b7ky1inlineContent" id="comp-kaw6b7k3inlineContent">
                                 <div className="layout-kaw709j9-item layout-kaw709j9-component style-kaw709jb animating-screenIn" id="comp-kaw709hk">
                                    <div id="comp-kaw709hklink" data-hook="ImageX-core-component" className="imagex3474482427__root style-kaw709jb">
                                          
                                          <img id='CoffeeCup' src={CoffeeCup} alt='CoffeeCup' className="fullwidth" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-is-absolute-layout="false" className="layout-kaw5rxih1-item layout-kaw5rxih1-component style-kaw5rxii1" id="comp-kaw5rxh2">
                              <div id="comp-kaw5rxh2bg" className="style-kaw5rxii1bg"></div>
                              <div className="layout-kaw5rxih1-container style-kaw5rxii1inlineContent" id="comp-kaw5rxh2inlineContent">
                                 <div data-is-absolute-layout="false" className="layout-kb0oterd-item layout-kb0oterd-component style-kb0otere1" id="comp-kb0oteou">
                                    <div id="comp-kb0oteoubg" className="style-kb0otere1bg"></div>
                                    <div className="layout-kb0oterd-container style-kb0otere1inlineContent" id="comp-kb0oteouinlineContent">
                                       <div className="layout-kaw5vmy2-item layout-kaw5vmy2-component style-kaw5vmy3" id="comp-kaw5vmxx">
                                          <h3 className="font_3 mobile-font-header1">Enterprise Software Development</h3>
                                       </div>
                                       <div className="layout-kaw5w83g1-item layout-kaw5w83g1-component style-kaw5w83h" id="comp-kaw5w81n">
                                          <p className="font_8 font-justify mobile-font-set2"><span>Our custom enterprise software development services allow enterprises to equip their workforce with better productivity tools and capabilities. We help them serve their customers faster with customizable ERP, CRM, HRM, Field Management, Logistics & Supply Chain, Mobility, Information Access and many other solutions. With our solutions, enterprises can become leaner and agile entities and save costs significantly due to improved efficiency and agility.</span></p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-is-absolute-layout="false" className="layout-kaw5u9bd1-item layout-kaw5u9bd1-component style-kaw5u9be2" id="comp-kaw5u9b4">
                              <div id="comp-kaw5u9b4bg" className="style-kaw5u9be2bg"></div>
                              <div className="layout-kaw5u9bd1-container style-kaw5u9be2inlineContent" id="comp-kaw5u9b4inlineContent">
                                 <div className="layout-kaw714pf-item layout-kaw714pf-component style-kaw714pi1 animating-screenIn" id="comp-kaw714nr">
                                    <div id="comp-kaw714nrlink" data-hook="ImageX-core-component" className="imagex3474482427__root style-kaw714pi1">
                                      <img id='Files' src={Files} alt='Files' className="pic-height" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-is-absolute-layout="false" className="layout-kaw6bobj1-item layout-kaw6bobj1-component style-kaw6bobk3" id="comp-kaw6bobi">
                              <div id="comp-kaw6bobibg" className="style-kaw6bobk3bg"></div>
                              <div className="layout-kaw6bobj1-container style-kaw6bobk3inlineContent" id="comp-kaw6bobiinlineContent">
                                 <div className="layout-kaw72q83-item layout-kaw72q83-component style-kaw72q86 animating-screenIn" id="comp-kaw72q6q">
                                    <div id="comp-kaw72q6qlink" data-hook="ImageX-core-component" className="imagex3474482427__root style-kaw72q86">
                                          <img id='Rocket' src={Rocket} alt='Rocket' className="fullwidth" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-is-absolute-layout="false" className="layout-kaw6fern-item layout-kaw6fern-component style-kaw6fero1" id="comp-kaw6ferh">
                              <div id="comp-kaw6ferhbg" className="style-kaw6fero1bg"></div>
                              <div className="layout-kaw6fern-container style-kaw6fero1inlineContent" id="comp-kaw6ferhinlineContent">
                                 <div className="layout-kaw71r8p-item layout-kaw71r8p-component style-kaw71r8r animating-screenIn" id="comp-kaw71r74">
                                    <div id="comp-kaw71r74link" data-hook="ImageX-core-component" className="imagex3474482427__root style-kaw71r8r">
                                      
                                      <img id='SPOTLIGHT' src={SPOTLIGHT} alt='SPOTLIGHT' className="pic-height" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-is-absolute-layout="false" className="layout-kaw6jiuf1-item layout-kaw6jiuf1-component style-kaw6jiug3" id="comp-kaw6jiuc">
                              <div id="comp-kaw6jiucbg" className="style-kaw6jiug3bg"></div>
                              <div className="layout-kaw6jiuf1-container style-kaw6jiug3inlineContent" id="comp-kaw6jiucinlineContent">
                                 <div className="layout-kaw73edq1-item layout-kaw73edq1-component style-kaw73edr1 animating-screenIn" id="comp-kaw73ebq">
                                    <div id="comp-kaw73ebqlink" data-hook="ImageX-core-component" className="imagex3474482427__root style-kaw73edr1">
                                       
                                    <img id='Bullhorn' src={Bullhorn} alt='Bullhorn' className="fullwidth" />
                                      
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-is-absolute-layout="false" className="layout-kaw6hljk1-item layout-kaw6hljk1-component style-kaw6hljm1" id="comp-kaw6hlim">
                              <div id="comp-kaw6hlimbg" className="style-kaw6hljm1bg"></div>
                              <div className="layout-kaw6hljk1-container style-kaw6hljm1inlineContent" id="comp-kaw6hliminlineContent">
                                 <div data-is-absolute-layout="false" className="layout-kb0oufh6-item layout-kb0oufh6-component style-kb0oufh71" id="comp-kb0oufey">
                                    <div id="comp-kb0oufeybg" className="style-kb0oufh71bg"></div>
                                    <div className="layout-kb0oufh6-container style-kb0oufh71inlineContent" id="comp-kb0oufeyinlineContent">
                                       <div className="layout-kaw6ibwq2-item layout-kaw6ibwq2-component style-kaw6ibwr1" id="comp-kaw6ibvr">
                                          <h3 className="font_3 mobile-font-header1">Hybrid Mobile App Development</h3>
                                       </div>
                                       <div className="layout-kaw6ibu9-item layout-kaw6ibu9-component style-kaw6ibua" id="comp-kaw6ibsj">
                                          <p className="font_8 font-justify mobile-font-set2"><span>Cross-platform integration to include all the functionalities for the user is becoming mandatory for every business that wants to be loved by its customers. Entvickler have a specific environment to develop hybrid apps and integrate technologies seamlessly to ensure an interactive display of services. Our experts have extensive knowledge of the diverse needs of customers. It has made us the leading hybrid mobile app development solutions provider in the industry. Hybrid apps always require developers with deep expertise who knows the key differences between a native app and a hybrid app.
                                                                            <ul className="list-none mobile-font-set2"><li>Custom Hybrid Apps</li><li>Hybrid application maintenance and support</li><li>Latest Mobile device frameworks</li><li>Tech-savvy Developers</li></ul>	
                                                                            </span></p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-is-absolute-layout="false" className="layout-kaw6p8p8-item layout-kaw6p8p8-component style-kaw6p8p92" id="comp-kaw6p8oz">
                              <div id="comp-kaw6p8ozbg" className="style-kaw6p8p92bg"></div>
                              <div className="layout-kaw6p8p8-container style-kaw6p8p92inlineContent" id="comp-kaw6p8ozinlineContent">
                                 <div className="layout-kaw74w5s-item layout-kaw74w5s-component style-kaw74w5v animating-screenIn" id="comp-kaw74w1i">
                                    <div id="comp-kaw74w1ilink" data-hook="ImageX-core-component" className="imagex3474482427__root style-kaw74w5v">
                                    <img id='Box' src={Box} alt='Box' className="fullwidth" />
                                     
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-is-absolute-layout="false" className="layout-kaw6o7ap1-item layout-kaw6o7ap1-component style-kaw6o7ar" id="comp-kaw6o7aj">
                              <div id="comp-kaw6o7ajbg" className="style-kaw6o7arbg"></div>
                              <div className="layout-kaw6o7ap1-container style-kaw6o7arinlineContent" id="comp-kaw6o7ajinlineContent">
                                 <div data-is-absolute-layout="false" className="layout-kb0oxfum-item layout-kb0oxfum-component style-kb0oxfuo1" id="comp-kb0oxfs2">
                                    <div id="comp-kb0oxfs2bg" className="style-kb0oxfuo1bg"></div>
                                    <div className="layout-kb0oxfum-container style-kb0oxfuo1inlineContent" id="comp-kb0oxfs2inlineContent">
                                       <div className="layout-kaw6o7au-item layout-kaw6o7au-component style-kaw6o7av2" id="comp-kaw6o7as">
                                          <h3 className="font_3 mobile-font-header1">UX Strategy and Design
                                          </h3>
                                       </div>
                                       <div className="layout-kaw6o7aw3-item layout-kaw6o7aw3-component style-kaw6o7ay" id="comp-kaw6o7aw">
                                          <p className="font_8 font-justify mobile-font-set2"><span>Before jumping into the development process, crafting a compelling and clear UX strategy is compulsory to avoid inconsistency through design structure. Entvickler ensures a unique and attractive visual design in custom-crafted UX Strategy. Appealing designs throughout your website or apps ensures amazing experiences for the user and they keep coming back. Our experts having rich and diversified experience in Designing Interactive Experiences gives you unmatchable results with a quick and trustworthy process.</span></p>
                                         
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-is-absolute-layout="false" className="layout-kaw797rs-item layout-kaw797rs-component style-kaw797rt1" id="comp-kaw797rn">
                              <div id="comp-kaw797rnbg" className="style-kaw797rt1bg"></div>
                              <div className="layout-kaw797rs-container style-kaw797rt1inlineContent" id="comp-kaw797rninlineContent">
                                 <div className="layout-kaw7edy7-item layout-kaw7edy7-component style-kaw7edy81 animating-screenIn" id="comp-kaw7edwj">
                                    <div id="comp-kaw7edwjlink" data-hook="ImageX-core-component" className="imagex3474482427__root style-kaw7edy81">
                                    <img id='Paperplane' src={Paperplane} alt='Paperplane' className="fullwidth" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-is-absolute-layout="false" className="layout-kaw76c0s1-item layout-kaw76c0s1-component style-kaw76c0t2" id="comp-kaw76c0o">
                              <div id="comp-kaw76c0obg" className="style-kaw76c0t2bg"></div>
                              <div className="layout-kaw76c0s1-container style-kaw76c0t2inlineContent" id="comp-kaw76c0oinlineContent">
                                 <div className="layout-kaw78f96-item layout-kaw78f96-component style-kaw78f98 animating-screenIn" id="comp-kaw78f4y">
                                    <div id="comp-kaw78f4ylink" data-hook="ImageX-core-component" className="imagex3474482427__root style-kaw78f98">
                                    <img id='Planet' src={Planet} alt='Planet' className="fullwidth" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-is-absolute-layout="false" className="layout-kaw5pd17-item layout-kaw5pd17-component style-kaw5pd19" id="comp-kaw5pcz9">
                              <div id="comp-kaw5pcz9bg" className="style-kaw5pd19bg"></div>
                              <div className="layout-kaw5pd17-container style-kaw5pd19inlineContent" id="comp-kaw5pcz9inlineContent">
                                 <div data-is-absolute-layout="false" className="layout-kazdmwyi-item layout-kazdmwyi-component style-kazdmwys1" id="comp-kazdmwrn">
                                    <div id="comp-kazdmwrnbg" className="style-kazdmwys1bg"></div>
                                    <div className="layout-kazdmwyi-container style-kazdmwys1inlineContent" id="comp-kazdmwrninlineContent">
                                       <div  className="layout-kaw5lvw5-item layout-kaw5lvw5-component style-kaw5lvw7 animating-screenIn" id="comp-kaw5lvub">
                                          <h2 className="font_0" >COMPANY</h2>
                                       </div>
                                       <div className="layout-kaw5kp7m-item layout-kaw5kp7m-component style-kaw5kp7o" id="comp-kaw5kp66">
                                          <p className="font_5" >Great Is More Than the
                                             Sum of Its Parts
                                          </p>
                                       </div>
                                       <div id="comp-kb0orv5e" data-align="center" data-disabled="false" data-margin="0" data-should-use-flex="true" data-width="160" data-height="90" className="layout-kb0orv65-item layout-kb0orv65-component style-kb0orv68" data-state="desktop shouldUseFlex center"><a id="comp-kb0orv5elink" className="style-kb0orv68link"><span id="comp-kb0orv5elabel" className="style-kb0orv68label text-size-button">What We Deliver</span></a></div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                      </div>
              </section>
              
        </div>
      </div>
    </div>

<div className="container-fluid p-0 m-0" id="products">
    <div data-is-absolute-layout="false" className="layout-kawghdc21-item" id="comp-kawghd9t">
            <div id="comp-kawghd9tbg" className="style-kawghdc4bg"></div>
            <div className="layout-kawghdc21-container style-kawghdc4inlineContent" id="comp-kawghd9tinlineContent">
                <div data-is-absolute-layout="false" className="layout-kazdnuaf-item layout-kazdnuaf-component style-kazdnuag1" id="comp-kazdnu3q">
                    <div id="comp-kazdnu3qbg" className="style-kazdnuag1bg"></div>
                    <div className="layout-kazdnuaf-container style-kazdnuag1inlineContent" id="comp-kazdnu3qinlineContent">
                        <div  className="layout-kaw5lvw5-item layout-kaw5lvw5-component style-kaw5lvw7 animating-screenIn">
                        <h2 className="font_0">PRODUCTS</h2>
                        </div>
                        <div className="layout-kawh3ay1-item layout-kawh3ay1-component style-kawh3ay2" id="comp-kawh3aqm">
                        <p className="font_5 great-things" >With the Right Software,Great Things Can Happen</p>
                        </div>
                    </div>
            <div className="layout-kaw821eu-container style-kaw821ev1inlineContent" id="comp-kaw821e4inlineContent">
            <div data-is-absolute-layout="false" className="layout-kb0obk0m-item layout-kb0obk0m-component style-kb0obk0o" id="comp-kb0objyd">
                <div id="comp-kb0objydbg" className="style-kb0obk0obg"></div>
                    <div className="layout-kb0obk0m-container style-kb0obk0oinlineContent" id="comp-kb0objydinlineContent">
                    <div  className="layout-kaw85rla-item layout-kaw85rla-component style-kaw85rlb1 great-things-content" id="comp-kaw85rj1">
                        <p className="font_8 font-justify"><span>Software product development requires a deep understanding of the domain and modern technologies that can integrate seamlessly to deliver the best results. Our Experts for Software Product Solutions have designed and developed hundreds of Software Products Successfully. At Entvickler, we are flexible to incorporate evolving changes and agile methodology helps us to attain the goals. Starting with requirements specifications, we thoroughly exercise Testing in every phase of development. Entvickler to develop the best Software Product for your blooming business.</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
    
    <div className="container-fluid m-0 p-0 fluid-background">
        <div className="row">
            <div className="col-sm-12">
                <div className="section-header text-center section-border-3">
                    <h2>Solutions <small>that are ready-made, bespoke and save your time and money</small></h2>
                </div>
            </div>
        </div>
        <ul className="row featured-solutions margin-left-15rem">
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={chat_app} /> <h4>Chat App</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={classified_app} /> <h4>Classified App</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={crowdfunding} /> <h4>Crowdfunding</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={custom_product_design}/> <h4>Custom Design</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={dating_app} /> <h4>Dating App</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={fitness_app} /> <h4>Fitness App</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={food_delivery} /> <h4>Food Delivery App</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={job_portal} /> <h4>Job Portal</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={learning_management} /> <h4>Learning Management</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={multiple_vendors} /> <h4>Multiple Vendors</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={on_demand_service} /> <h4>On-Demand Service</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={online_grocery} /> <h4>Online Grocery</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={real_estate_app} /> <h4>Real Estate</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={ride_sharing} /> <h4>Ride Sharing</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={salon_spa} /> <h4>Salon/Spa</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={social_media} /> <h4>Social Media</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={travel_hospitality} /> <h4>Travel &amp; Hospitality</h4> </div> </li>
            <li className="col-4"> <div className="solution-box"> <img className="lazy" width="60" height="60" alt="" src={travel_tourism} /> <h4>Travel &amp; Tourism</h4> </div> </li>
        </ul>
        <section data-is-absolute-layout="false" className="layout-kaw8qvq0-item layout-kaw8qvq0-component style-kaw8qvq51" id="comp-kaw8qvop">
                <div id="comp-kaw8qvopbg" className="style-kaw8qvq51bg"></div>
                <div className="layout-kaw8qvq0-container style-kaw8qvq51inlineContent" id="comp-kaw8qvopinlineContent">
                    <div className="layout-kbg7rnpw-item layout-kbg7rnpw-component style-kbg7rnpx" id="comp-kbg7rne8">
                    <div id="comp-kbg7rne8link" data-hook="ImageX-core-component" className="imagex3474482427__root style-kbg7rnpx">
                    <img id='Services' src={Services} alt='Services' />
                    </div>
                    </div>
                </div>
            </section>
    </div>
</div>
<div className="container-fluid p-0 m-0 fluid-background" id="services">
<div data-is-absolute-layout="false" className="layout-kawghdc21-item" id="comp-kawghd9t">
            <div id="comp-kawghd9tbg" className="style-kawghdc4bg"></div>
            <div className="layout-kawghdc21-container style-kawghdc4inlineContent" id="comp-kawghd9tinlineContent">
                <div data-is-absolute-layout="false" className="layout-kazdnuaf-item layout-kazdnuaf-component style-kazdnuag1" id="comp-kazdnu3q">
                    <div id="comp-kazdnu3qbg" className="style-kazdnuag1bg"></div>
                    <div className="layout-kazdnuaf-container style-kazdnuag1inlineContent" id="comp-kazdnu3qinlineContent">
                        <div  className="layout-kaw5lvw5-item layout-kaw5lvw5-component style-kaw5lvw7 animating-screenIn">
                        <h2 className="font_0">SERVICES</h2>
                        </div>
                        <div className="layout-kawh3ay1-item layout-kawh3ay1-component style-kawh3ay2" id="comp-kawh3aqm">
                        <p className="font_5 devops-consult">DevOps Consulting and Development</p>
                        </div>
                    </div>
            <div className="layout-kaw821eu-container style-kaw821ev1inlineContent" id="comp-kaw821e4inlineContent">
            <div data-is-absolute-layout="false" className="layout-kb0obk0m-item layout-kb0obk0m-component style-kb0obk0o" id="comp-kb0objyd">
                <div id="comp-kb0objydbg" className="style-kb0obk0obg"></div>
                    <div className="layout-kb0obk0m-container style-kb0obk0oinlineContent" id="comp-kb0objydinlineContent">
                    <div  className="layout-kaw85rla-item layout-kaw85rla-component style-kaw85rlb1 devops-consult-content" id="comp-kaw85rj1">
                        <p className="font_8 font-justify"><span>Our DevOps consulting services allow you to minimize costs on your existing infrastructure while making it more scalable, flexible, resilient and secure. Our experienced team of engineers and software developers analyzes your existing IT infrastructure and development processes and recommends the most efficient pathway forward according to your organization’s needs and best practices.</span></p>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        
    </div>
    <div className="container p-3">
        <div className="section-header text-center section-border">

        <h2>Web Application Development</h2>
        </div> 
        <div className="row">
            <div className="col-md-6 col-lg-4 qservices">
            <figure>
            <img src={cms} height="70" width="70" alt=""/>
            </figure>
            <h3>CMS Development</h3>
            <p>We offer you a comprehensive range of Content Management System (CMS) development services best suited to your specific business needs.</p>
            </div>
            <div className="col-md-6 col-lg-4 qservices">
            <figure>
            <img src={ecommerce} height="70" width="70" alt=""/>
            </figure>
            <h3>Ecommerce Development</h3>
            <p>We offer customized, easy to integrate, and dynamic online shopping store solution to help you get the best out of your online business.</p>
            </div>
            <div className="col-md-6 col-lg-4 qservices">
            <figure>
            <img src={custom} height="70" width="70" alt=""/>
            </figure>
            <h3>Custom Web App Development</h3>
            <p>We develop highly customized, dynamic, and easy to integrate websites and applications which enable you to create a strong online footprint.</p>
            </div>
            <div className="col-md-6 col-lg-4 qservices">
            <figure>
            <img src={api} height="70" width="70" alt=""/>
            </figure>
            <h3>API Development</h3>
            <p>We help you extend your online reach with API development enabling easy and accelerated integration between various softwares and technologies.</p>
            </div>
            <div className="col-md-6 col-lg-4 qservices">
            <figure>
            <img src={migration} height="70" width="70" alt=""/>
            </figure>
            <h3>Migration &amp; Upgradation</h3>
            <p>Migrate or upgrade your existing websites and applications to advanced versions with utmost ease and without any downtime or loss of data.</p>
            </div>
            <div className="col-md-6 col-lg-4 qservices">
            <figure>
            <img src={issues} height="70" width="70" alt=""/>
            </figure>
            <h3>Issues &amp; Bug Fixes</h3>
            <p>Resolve any issues and fix bugs actively during or after the completion of the coding and the development process as and when required.</p>
            </div>
        </div>
    </div>
    
    <div className="container">
    <div className="section-header text-center section-border">
        <h2>Skills and Technologies</h2>
        </div> 
        <div className="row">
            <div className="col-sm-12 text-center">
                <ul className="row">
                    <li className="col-4 lipad"><div className="card tech-card"><figure><img src={angular} height="60" width="60" alt=""/></figure><p>ANGULAR</p></div></li>
                            <li className="col-4 lipad"><div className="card tech-card"><figure><img src={aspnet} height="60" width="60" alt="" /></figure><p>ASP.NET</p> </div></li>
                            <li className="col-4 lipad"> <div className="card tech-card"><figure><img src={java} height="60" width="60" alt="" /></figure><p>JAVA</p> </div></li>
                            <li className="col-4 lipad"><div className="card tech-card"> <figure><img src={laravel} height="60" width="60" alt="" /></figure><p>LARAVEL</p></div> </li>
                            <li className="col-4 lipad"><div className="card tech-card"> <figure><img src={nodejs} height="60" width="60" alt="" /></figure><p>NODE JS</p> </div></li>
                            <li className="col-4 lipad"><div className="card tech-card"> <figure><img src={python} height="60" width="60" alt="" /></figure><p>PYTHON</p></div></li>
                            <li className="col-4 lipad"><div className="card tech-card"> <figure><img src={sharepoint} height="60" width="60" alt="" /></figure><p>SHAREPOINT</p></div> </li>
                            <li className="col-4 lipad"><div className="card tech-card"> <figure><img src={wordpress} height="60" width="60" alt="" /></figure><p>WORDPRESS</p></div> </li>
                            <li className="col-4 lipad"><div className="card tech-card"> <figure><img src={php} height="60" width="60" alt="" /></figure><p>PHP</p></div> </li>
                </ul>
            </div>
        </div>
    </div>
    
 <div className="container pt-6">
        <div className="section-header text-center section-border">
        <h2>Mobile  Application Development</h2>
        </div> 
        <div className="row">
            <div className="col-md-6 col-lg-4 qservices"> <figure> <img src={ui_ux} height="70" width="70" alt=""/> </figure> <h3>UI/UX</h3> <p>Create a responsive application user interface (UI) with thoughtful and highly engaging user experience (UX).</p> </div>
            <div className="col-md-6 col-lg-4 qservices"> <figure> <img src={business_app} height="70" width="70" alt=""/> </figure> <h3>Business Apps</h3> <p>Design and develop high performance and dynamic business apps to ensure best ROI, keeping in view your business requirements.</p> </div>
            <div className="col-md-6 col-lg-4 qservices"> <figure> <img src={ar_vr} height="70" width="70" alt=""/> </figure> <h3>AR/VR Apps</h3> <p>Develop rich, immersive and interactive Augmented Reality (AR) and Virtual Reality (VR) application for your users to stay connected with your content.</p> </div>
            <div className="col-md-6 col-lg-4 qservices"> <figure> <img src={amp} height="70" width="70" alt=""/> </figure> <h3>AMP</h3> <p>Develop Accelerated Mobile Pages (AMP) for improved viewership of your content by bringing down the load time while keeping the integrity of your content.</p> </div>
            <div className="col-md-6 col-lg-4 qservices"> <figure> <img src={migration} height="70" width="70" alt=""/> </figure> <h3>Migration & Updates</h3> <p>Migrate or upgrade your existing applications to the newer and advanced versions securely without any downtime or loss of application data.</p> </div>
            <div className="col-md-6 col-lg-4 qservices"> <figure> <img src={maintenence} height="70" width="70" alt=""/> </figure> <h3>Maintenance</h3> <p>Maintain the applications using measures like functionality and technical enhancements, regular code reviews and testings, design updates, etc.</p> </div>
        </div>
    </div>
    <div className="container">
    <div className="section-header text-center section-border">
        <h2>Skills and Technologies</h2>
        </div> 
        <div className="row">
            <div className="col-sm-12 text-center">
                <ul className="row">
                    <li className="col-4"> <figure><img src={android} height="60" width="60" alt=""/></figure><p>ANDROID</p> </li>
                    <li className="col-4"> <figure><img src={ios} height="60" width="60" alt=""/></figure><p>IOS </p> </li>
                    <li className="col-4"> <figure><img src={react_native} height="60" width="60" alt=""/></figure><p>REACT-NATIVE</p> </li>
                    <li className="col-4"> <figure><img src={flutter} height="60" width="60" alt=""/></figure><p>FLUTTER </p> </li>
                    <li className="col-4"> <figure><img src={hybrid} height="60" width="60" alt=""/></figure><p>HYBRID</p> </li>
                    <li className="col-4"> <figure><img src={ar_vr} height="60" width="60" alt=""/></figure><p>AR/VR </p> </li>
                    <li className="col-4"> <figure><img src={amp} height="60" width="60" alt=""/></figure><p>AMP</p> </li>
                </ul>
            </div>
        </div>
    </div>
    <section className="qservicesbg reset-bg section-padding">
    <div className="container">
        <div className="section-header text-center section-border">

            <h2>IoT Services we offer</h2>
        </div>
        <div className="row">
            <div className="col-md-6 col-lg-4 qservices">
                <figure>
                    <img src={platform} height="70" width="70" alt="" />
                </figure>
                <h3>Platform Identification and Evaluation</h3>
                <p>Identify the platforms to be utilized and evaluate the issues and challenges and their impact on the business, technology, and customers.</p>
            </div>
            <div className="col-md-6 col-lg-4 qservices">
                <figure>
                    <img src={solution} height="70" width="70" alt="" />
                </figure>
                <h3>Solution Architecture</h3>
                <p>On the basis of the problem, guidelines, and the best practices a map of various IoT and associated technical solution infrastructure is devised.</p>
            </div>
            <div className="col-md-6 col-lg-4 qservices">
                <figure>
                    <img src={integration} height="70" width="70" alt="" />
                </figure>
                <h3>Integration with IoT Platforms</h3>
                <p>Develop solutions to enable seamless cross device and technology integration and redefine product offerings and customer experiences.</p>
            </div>
            <div className="col-md-6 col-lg-4 qservices">
                <figure>
                    <img src={app} height="70" width="70" alt="" />
                </figure>
                <h3>App Development</h3>
                <p>Develop scalable, secure, bug-free and easy to integrate IoT applications which are customized to best suit your business requirements.</p>
            </div>
            <div className="col-md-6 col-lg-4 qservices">
                <figure>
                    <img src={migration} height="70" width="70" alt="" />
                </figure>
                <h3>System Integration</h3>
                <p>Connect devices, sensors and other machines and integrate with the current systems enabling you to capture and benefit from the data immediately.</p>
            </div>
            <div className="col-md-6 col-lg-4 qservices">
                <figure>
                    <img src={issues} height="70" width="70" alt="" />
                </figure>
                <h3>Support &amp; Enhancements</h3>
                <p>Fix issues bugs actively and maintain and enhance the applications using functionality and technical enhancements, design updates, etc.</p>
            </div>
        </div>
    </div>
</section>
<section data-is-absolute-layout="false" className="layout-kaw8qvq0-item layout-kaw8qvq0-component style-kaw8qvq51" id="comp-kaw8qvop">
    <div id="comp-kaw8qvopbg" className="style-kaw8qvq51bg"></div>
    <div className="layout-kaw8qvq0-container style-kaw8qvq51inlineContent" id="comp-kaw8qvopinlineContent">
        <div className="layout-kbg7rnpw-item layout-kbg7rnpw-component style-kbg7rnpx" id="comp-kbg7rne8">
        <div id="comp-kbg7rne8link" data-hook="ImageX-core-component" className="imagex3474482427__root style-kbg7rnpx">
        <img id='Random' src={Random} alt='Random' className="fullwidth" />
        
        </div>
        </div>
    </div>
</section>
</div>
    {/* <section data-is-absolute-layout="false" className="layout-kaw8b5f2-item layout-kaw8b5f2-component style-kaw8b5f5" id="comp-kaw8b5eb">
                        <div id="comp-kaw8b5ebbg" className="style-kaw8b5f5bg"></div>
                        <div className="layout-kaw8b5f2-container style-kaw8b5f5inlineContent" id="comp-kaw8b5ebinlineContent">
                           <div data-is-absolute-layout="false" className="layout-kawjqf3a1-item layout-kawjqf3a1-component style-kawjqf3c1" id="comp-kawjqf11">
                              <div id="comp-kawjqf11bg" className="style-kawjqf3c1bg"></div>
                              <div className="layout-kawjqf3a1-container style-kawjqf3c1inlineContent" id="comp-kawjqf11inlineContent">
                                 <div data-is-absolute-layout="false" className="layout-kaxjclyy-item layout-kaxjclyy-component style-kaxjclz71" id="comp-kaxjclyp">
                                    <div id="comp-kaxjclypbg" className="style-kaxjclz71bg"></div>
                                    <div className="layout-kaxjclyy-container style-kaxjclz71inlineContent" id="comp-kaxjclypinlineContent">
                                       <div data-svg-id="5b241b3ec1d049dcb1c242869657566d.svg" data-svg-type="color" data-display-mode="fit" data-viewbox="0 0 200 200" data-preserve-viewbox="ignore" className="layout-kaxjcm00-item layout-kaxjcm00-component style-kaxjcm004" id="comp-kaxjclzs3">
                                          
                                          <div id="comp-kaxjclzs3link" className="style-kaxjcm004link">
                                             <div  className="style-kaxjcm004_comp-kaxjclzs3 style-kaxjcm004_non-scaling-stroke style-kaxjcm004svg" id="comp-kaxjclzs3svg">
                                                <svg preserveAspectRatio="xMidYMid meet" id="comp-kaxjclzs3svgcontent" data-bbox="18 32 164 135.886" xmlns="http://www.w3.org/2000/svg" viewBox="18 32 164 135.886" height="200" width="200" data-type="color" role="img" aria-labelledby="comp-kaxjclzs3-svgtitle">
                                                   <title id="comp-kaxjclzs3-svgtitle">quotation mark</title>
                                                   <g>
                                                      <path d="M18 32h74.971v60.914c-1.098 35.403-20.114 60.394-57.047 74.972L19.629 138.21c22.812-9.372 34.218-24.47 34.218-45.296H18V32zm89.029 0H182v60.914c-1.098 35.403-20.114 60.394-57.048 74.972l-16.294-29.676c22.812-9.372 34.218-24.47 34.218-45.296h-35.847V32z" fill="#F65F58" fill-rule="evenodd" data-color="1"></path>
                                                   </g>
                                                </svg>
                                             </div>
                                          </div>
                                       </div>
                                       <div  className="layout-kaxjclzf-item layout-kaxjclzf-component style-kaxjclzg3" id="comp-kaxjclz9">
                                          <p className="font_8"><span>The team of Entvickler is great. Working with full dedication and creativity. Loved the concept and process they had shared with Horizons International group.</span></p>
                                       </div>
                                       <div data-is-absolute-layout="false" className="layout-kaxjclzi1-item layout-kaxjclzi1-component style-kaxjclzn1" id="comp-kaxjclzh">
                                          <div id="comp-kaxjclzhbg" className="style-kaxjclzn1bg"></div>
                                          <div className="layout-kaxjclzi1-container style-kaxjclzn1inlineContent" id="comp-kaxjclzhinlineContent">
                                             <div  className="layout-kaxjclzr2-item layout-kaxjclzr2-component style-kaxjclzs1" id="comp-kaxjclzq">
                                                <p className="font_7"><span>Jaydel Gluckie
                                                   Manager, .NET Development / IT Infrastructure at Horizons International</span>
                                                </p>
                                                <p className="font_6"><span>Calgary, Alberta, Canada</span>
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div data-is-absolute-layout="false" className="layout-kawlmvdv-item layout-kawlmvdv-component style-kawlmvdw1" id="comp-kawlmvbd">
                                    <div id="comp-kawlmvbdbg" className="style-kawlmvdw1bg"></div>
                                    <div className="layout-kawlmvdv-container style-kawlmvdw1inlineContent" id="comp-kawlmvbdinlineContent">
                                       <div data-svg-id="5b241b3ec1d049dcb1c242869657566d.svg" data-svg-type="color" data-display-mode="fit" data-viewbox="0 0 200 200" data-preserve-viewbox="ignore" className="layout-kawlruug-item layout-kawlruug-component style-kawlruui" id="comp-kawlrur8">
                                         
                                          <div id="comp-kawlrur8link" className="style-kawlruuilink">
                                             <div  className="style-kawlruui_comp-kawlrur8 style-kawlruui_non-scaling-stroke style-kawlruuisvg" id="comp-kawlrur8svg">
                                                <svg preserveAspectRatio="xMidYMid meet" id="comp-kawlrur8svgcontent" data-bbox="18 32 164 135.886" xmlns="http://www.w3.org/2000/svg" viewBox="18 32 164 135.886" height="200" width="200" data-type="color" role="img" aria-labelledby="comp-kawlrur8-svgtitle">
                                                   <title id="comp-kawlrur8-svgtitle">quotation mark</title>
                                                   <g>
                                                      <path d="M18 32h74.971v60.914c-1.098 35.403-20.114 60.394-57.047 74.972L19.629 138.21c22.812-9.372 34.218-24.47 34.218-45.296H18V32zm89.029 0H182v60.914c-1.098 35.403-20.114 60.394-57.048 74.972l-16.294-29.676c22.812-9.372 34.218-24.47 34.218-45.296h-35.847V32z" fill="#F65F58" fill-rule="evenodd" data-color="1"></path>
                                                   </g>
                                                </svg>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="layout-kawlm673-item layout-kawlm673-component style-kawlm675" id="comp-kawlm64u">
                                          <p className="font_8"><span>Entvickler Team is Awesome.They have provided a level of professional service, meeting my identified business needs in a very friendly and reliable manner.</span></p>
                                       </div>
                                       <div data-is-absolute-layout="false" className="layout-kawlmf871-item layout-kawlmf871-component style-kawlmf882" id="comp-kawlmf61">
                                          <div id="comp-kawlmf61bg" className="style-kawlmf882bg"></div>
                                          <div className="layout-kawlmf871-container style-kawlmf882inlineContent" id="comp-kawlmf61inlineContent">
                                             <div  className="layout-kawlp4pk1-item layout-kawlp4pk1-component style-kawlp4pl" id="comp-kawlp4nh">
                                                <p className="font_7"><span>Joe Cunsolo,
                                                Director Software and Services, Blue Planet</span>
                                                </p>
                                                <p className="font_5"><span>Melbourne, Victoria, Australia</span>
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div data-is-absolute-layout="false" className="layout-kaxjdt5t-item layout-kaxjdt5t-component style-kaxjdt5v" id="comp-kaxjdt5r">
                                    <div id="comp-kaxjdt5rbg" className="style-kaxjdt5vbg"></div>
                                    <div className="layout-kaxjdt5t-container style-kaxjdt5vinlineContent" id="comp-kaxjdt5rinlineContent">
                                       <div data-svg-id="5b241b3ec1d049dcb1c242869657566d.svg" data-svg-type="color" data-display-mode="fit" data-viewbox="0 0 200 200" data-preserve-viewbox="ignore" className="layout-kaxjdt65-item layout-kaxjdt65-component style-kaxjdt654" id="comp-kaxjdt622">
                                         
                                          <div id="comp-kaxjdt622link" className="style-kaxjdt654link">
                                             <div className="style-kaxjdt654_comp-kaxjdt622 style-kaxjdt654_non-scaling-stroke style-kaxjdt654svg" id="comp-kaxjdt622svg">
                                                <svg preserveAspectRatio="xMidYMid meet" id="comp-kaxjdt622svgcontent" data-bbox="18 32 164 135.886" xmlns="http://www.w3.org/2000/svg" viewBox="18 32 164 135.886" height="200" width="200" data-type="color" role="img" aria-labelledby="comp-kaxjdt622-svgtitle">
                                                   <title id="comp-kaxjdt622-svgtitle">quotation mark</title>
                                                   <g>
                                                      <path d="M18 32h74.971v60.914c-1.098 35.403-20.114 60.394-57.047 74.972L19.629 138.21c22.812-9.372 34.218-24.47 34.218-45.296H18V32zm89.029 0H182v60.914c-1.098 35.403-20.114 60.394-57.048 74.972l-16.294-29.676c22.812-9.372 34.218-24.47 34.218-45.296h-35.847V32z" fill="#F65F58" fill-rule="evenodd" data-color="1"></path>
                                                   </g>
                                                </svg>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="layout-kaxjdt5w2-item layout-kaxjdt5w2-component style-kaxjdt5x1" id="comp-kaxjdt5v1">
                                          <p className="font_8"><span>Entvickler has the real right to get appreciations. Their process and efforts are the accelerators that keep us moving towards success.</span></p>
                                       </div>
                                       <div data-is-absolute-layout="false" className="layout-kaxjdt5y1-item layout-kaxjdt5y1-component style-kaxjdt5z4" id="comp-kaxjdt5x2">
                                          <div id="comp-kaxjdt5x2bg" className="style-kaxjdt5z4bg"></div>
                                          <div className="layout-kaxjdt5y1-container style-kaxjdt5z4inlineContent" id="comp-kaxjdt5x2inlineContent">
                                             <div  className="layout-kaxjdt611-item layout-kaxjdt611-component style-kaxjdt62" id="comp-kaxjdt601">
                                                <p className="font_7"><span>Meera AlMansoori
                                                   CEO, LAMEERA- UAE</span>
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              
                           </div>

                           <div data-is-absolute-layout="false" className="layout-kaw8cyer-item layout-kaw8cyer-component style-kaw8cyet" id="comp-kaw8cycq">
                              <div id="comp-kaw8cycqbg" className="style-kaw8cyetbg"></div>
                              <div className="layout-kaw8cyer-container style-kaw8cyetinlineContent" id="comp-kaw8cycqinlineContent">
                                 <div data-is-absolute-layout="false" className="layout-kb0p0uqh-item layout-kb0p0uqh-component style-kb0p0uqi2" id="comp-kb0p0uo9">
                                    <div id="comp-kb0p0uo9bg" className="style-kb0p0uqi2bg"></div>
                                    <div className="layout-kb0p0uqh-container style-kb0p0uqi2inlineContent" id="comp-kb0p0uo9inlineContent">
                                       <div  className="layout-kaw5lvw5-item layout-kaw5lvw5-component style-kaw5lvw7 animating-screenIn" id="comp-kaw5lvub">
                                           <h2 className="font_0" >TESTIMONIALS</h2>
                                       </div>
                                       <div  className="layout-kaw8bp04-item layout-kaw8bp04-component style-kaw8bp051" id="comp-kaw8boxu">
                                          <p className="font_5 client-saying" >What Our Clients Are Saying</p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </section> */}
                
  {/*  <section data-is-absolute-layout="false" className="layout-kaxjmobl-item layout-kaxjmobl-component style-kaxjmobv" id="clients">*/}
  {/*  <div id="comp-kaxjmob0bg" className="style-kaxjmobvbg"></div>*/}
  {/*  <div className="layout-kaxjmobl-container style-kaxjmobvinlineContent" id="comp-kaxjmob0inlineContent">*/}
  {/*      <div className="container hide-on-desktop">*/}
  {/*          <div className="row margin-minus-50">*/}
  {/*          <div className="col-sm-12 text-center">*/}
  {/*          <ul className="row featured-solutions margin-left-15rem margin-top-set-clients">*/}
  {/*                  <li className="col-sm-6 col-xs-6"> <div className="solution-box fullwidth"> <img className="lazy" width="450" src={BluePlanet} /> </div></li>*/}
  {/*                  <li className="col-sm-6 col-xs-6"> <div className="solution-box fullwidth"> <img className="lazy"  width="450" src={EScrappy} />  </div></li>*/}
  {/*                  <li className="col-sm-6 col-xs-6"> <div className="solution-box fullwidth"> <img className="lazy" width="450" src={LAMeera} />  </div></li>*/}
  {/*                  <li className="col-sm-6 col-xs-6"> <div className="solution-box fullwidth"> <img className="lazy" width="450" src={Esorr} /> </div></li>*/}
  {/*                  <li className="col-sm-6 col-xs-6"> <div className="solution-box fullwidth"> <img className="lazy" width="450" src={Horizons} /> </div></li>*/}
  {/*              </ul>*/}
  {/*              </div>*/}
  {/*          </div>*/}
  {/*      </div>*/}
  {/*      <div data-is-absolute-layout="false" className="layout-kazaywmf-item layout-kazaywmf-component style-kazaywmh show-on-desktop" id="comp-kazaywkl">*/}
  {/*          <div id="comp-kazaywklbg" className="style-kazaywmhbg"></div>*/}
  {/*          <div className="layout-kazaywmf-container style-kazaywmhinlineContent" id="comp-kazaywklinlineContent">*/}
  {/*              <div data-is-absolute-layout="false" className="layout-kazb20xf-item layout-kazb20xf-component style-kazb20xg1" id="comp-kazb20xd">*/}
  {/*                  <div id="comp-kazb20xdbg" className="style-kazb20xg1bg"></div>*/}
  {/*                  <div className="layout-kazb20xf-container style-kazb20xg1inlineContent" id="comp-kazb20xdinlineContent">*/}
  {/*                      <div className="layout-kazblvpy-item layout-kazblvpy-component style-kazblvpz1" id="comp-kazblvpv">*/}
  {/*                          <div id="comp-kazblvpvlink" data-hook="ImageX-core-component" className="imagex3474482427__root style-kazblvpz1">*/}
  {/*                             <div className="card full-height">*/}
  {/*                             <img id='Horizons' src={Horizons} alt='Horizons' className="scale-set" />*/}
  {/*                            </div>*/}
  {/*                          </div>*/}
  {/*                      </div>*/}
  {/*                  </div>*/}
  {/*              </div>*/}
  {/*              <div data-is-absolute-layout="false"*/}
  {/*                  className="layout-kaxjtfp31-item layout-kaxjtfp31-component style-kaxjtfp42" id="comp-kaxjtfot">*/}
  {/*                  <div id="comp-kaxjtfotbg" className="style-kaxjtfp42bg"></div>*/}
  {/*                  <div className="layout-kaxjtfp31-container style-kaxjtfp42inlineContent"*/}
  {/*                      id="comp-kaxjtfotinlineContent">*/}
  {/*                      <div className="layout-kazbldgl1-item layout-kazbldgl1-component style-kazbldgm1"*/}
  {/*                          id="comp-kazbldgg">*/}
  {/*                          <div id="comp-kazbldgglink" data-hook="ImageX-core-component"*/}
  {/*                              className="imagex3474482427__root style-kazbldgm1">*/}
                                
  {/*                              <div className="card full-height">*/}
  {/*                              <img id='BluePlanet' src={BluePlanet} alt='BluePlanet' className="scale-set"/>*/}
  {/*                            </div>*/}
  {/*                          </div>*/}
  {/*                      </div>*/}
  {/*                  </div>*/}
  {/*              </div>*/}
  {/*              <div data-is-absolute-layout="false"*/}
  {/*                  className="layout-kazb1a6h-item layout-kazb1a6h-component style-kazb1a6i1" id="comp-kazb1a6b">*/}
  {/*                  <div id="comp-kazb1a6bbg" className="style-kazb1a6i1bg"></div>*/}
  {/*                  <div className="layout-kazb1a6h-container style-kazb1a6i1inlineContent" id="comp-kazb1a6binlineContent">*/}
  {/*                      <div className="layout-kazbgtdl-item layout-kazbgtdl-component style-kazbgtdo" id="comp-kazbgt6m">*/}
  {/*                          <div id="comp-kazbgt6mlink" data-hook="ImageX-core-component"*/}
  {/*                              className="imagex3474482427__root style-kazbgtdo">*/}
  {/*                                 <div className="card full-height">*/}
  {/*                                 <img id='EScrappy' src={EScrappy} alt='EScrappy' className="scale-set"/>*/}
  {/*                            </div>*/}
                                   
  {/*                          </div>*/}
  {/*                      </div>*/}
  {/*                  </div>*/}
  {/*              </div>*/}
  {/*              <div data-is-absolute-layout="false"*/}
  {/*                  className="layout-kb6m9gn81-item layout-kb6m9gn81-component style-kb6m9gnb1" id="comp-kb6m9gml">*/}
  {/*                  <div id="comp-kb6m9gmlbg" className="style-kb6m9gnb1bg"></div>*/}
  {/*                  <div className="layout-kb6m9gn81-container style-kb6m9gnb1inlineContent"*/}
  {/*                      id="comp-kb6m9gmlinlineContent">*/}
  {/*                      <div className="layout-kazbn7oa-item layout-kazbn7oa-component style-kazbn7ob1" id="comp-kazbn7o4">*/}
  {/*                          <div id="comp-kazbn7o4link" data-hook="ImageX-core-component"*/}
  {/*                              className="imagex3474482427__root style-kazbn7ob1">*/}
  {/*                              <div className="card full-height custom-height">*/}
  {/*                              <img id='LAMeera' src={LAMeera} alt='LAMeera' className="scale-set"/>*/}
  {/*                            </div>*/}
  {/*                          </div>*/}
  {/*                      </div>*/}
  {/*                  </div>*/}
  {/*              </div>*/}
  {/*              <div data-is-absolute-layout="false"*/}
  {/*                  className="layout-kazb0k60-item layout-kazb0k60-component style-kazb0k611" id="comp-kazb0k5v">*/}
  {/*                  <div id="comp-kazb0k5vbg" className="style-kazb0k611bg"></div>*/}
  {/*                  <div className="layout-kazb0k60-container style-kazb0k611inlineContent" id="comp-kazb0k5vinlineContent">*/}
  {/*                      <div className="layout-kazbjvpz-item layout-kazbjvpz-component style-kazbjvq1" id="comp-kazbjvob">*/}
  {/*                          <div id="comp-kazbjvoblink" data-hook="ImageX-core-component"*/}
  {/*                              className="imagex3474482427__root style-kazbjvq1">*/}
                               
  {/*                             <div className="card full-height custom-height">*/}
  {/*                             <img id='Esorr' src={Esorr} alt='Esorr' className="scale-set"/>*/}
  {/*                            </div>*/}
  {/*                          </div>*/}
  {/*                      </div>*/}
  {/*                  </div>*/}
  {/*              </div>*/}
  {/*          </div>*/}
  {/*      </div>*/}
  {/*      <div data-is-absolute-layout="false" className="layout-kaxjn3t5-item layout-kaxjn3t5-component style-kaxjn3t61"*/}
  {/*          id="comp-kaxjn3qu">*/}
  {/*          <div id="comp-kaxjn3qubg" className="style-kaxjn3t61bg"></div>*/}
  {/*          <div className="layout-kaxjn3t5-container style-kaxjn3t61inlineContent" id="comp-kaxjn3quinlineContent">*/}
  {/*              <div data-is-absolute-layout="false"*/}
  {/*                  className="layout-kb0p1nat-item layout-kb0p1nat-component style-kb0p1nau2" id="comp-kb0p1n8f">*/}
  {/*                  <div id="comp-kb0p1n8fbg" className="style-kb0p1nau2bg"></div>*/}
  {/*                  <div className="layout-kb0p1nat-container style-kb0p1nau2inlineContent" id="comp-kb0p1n8finlineContent">*/}
  {/*                      <div */}
  {/*                          className="layout-kaxjn3tc2-item layout-kaxjn3tc2-component style-kaxjn3td1 animating-screenIn"*/}
  {/*                          id="comp-kaxjn3tb2">*/}
  {/*                          <h2 className="font_0" >INDUSTRY STANDARDS</h2>*/}
  {/*                      </div>*/}
  {/*                      <div */}
  {/*                          className="layout-kaxjn3ta-item layout-kaxjn3ta-component style-kaxjn3tb1" id="comp-kaxjn3t7">*/}
  {/*                          <p className="font_5" >Trusted by International Clients</p>*/}
  {/*                      </div>*/}
  {/*                  </div>*/}
  {/*              </div>*/}
  {/*          </div>*/}
  {/*      </div>*/}
  {/*  </div>*/}
  {/*</section>*/}
  <section data-is-absolute-layout="false" className="layout-kaw8qvq0-item layout-kaw8qvq0-component style-kaw8qvq51" id="comp-kaw8qvop">
              <div id="comp-kaw8qvopbg" className="style-kaw8qvq51bg"></div>
              <div className="layout-kaw8qvq0-container style-kaw8qvq51inlineContent" id="comp-kaw8qvopinlineContent">
                <div className="layout-kbg7rnpw-item layout-kbg7rnpw-component style-kbg7rnpx" id="comp-kbg7rne8">
                  <div id="comp-kbg7rne8link" data-hook="ImageX-core-component" className="imagex3474482427__root style-kbg7rnpx">
                  <img id='Services' src={Services} alt='Services' className="fullwidth" />
                  </div>
                </div>
              </div>
          </section>
 <section data-is-absolute-layout="false" className="layout-kaxjmobl-item layout-kaxjmobl-component" id="team">
    <div id="comp-kaxjmob0bg" className="style-kaxjmobvbg"></div>
    <div className="layout-kaxjmobl-container">
        {/* <div className="container-fluid margin-top-3rem">
            <div className="row">
                <div className="col-md-6">
                <div className="card team-image-card margin-left-15rem">
                            <img className="card-img-top" src={CEO} alt="Card image" />
                            <div className="card-body team-card-content">
                                        <p className="font_7"><span>SUNIL VIJAYAN,
                                        Founder and Chief Executive Officer(CEO)</span> </p>
                            </div>
                        </div>
                </div>
                <div className="col-md-6">
                <div className="card team-image-card">
                                <img className="card-img-top" src={COO} alt="Card image" />
                                <div className="card-body team-card-content">
                                            <p className="font_7"><span>SURAJ KUMAR,
                                            Co-Founder and Chief Operations Officer (COO)</span> </p>
                                </div>
                            </div>
                </div>
            </div>
        </div> */}
        <section id="mission-vision" className="section-padding">
            <div className="mission-vision">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                        <div className="section-header text-center section-border mission-head">
                        <h2>Mission &amp; Vision</h2>
                        </div>
                        </div>
                        </div>
                        <div className="row mission-vission-bg">
                        <div className="col-sm-6 left">
                        <h4>OUR MISSION</h4>
                        <p>Entvickler to be the Key to Success for Converting your Dreams into Reality through Innovative IT Solutions and Products</p>
                        </div>
                        <div className="col-sm-6 right">
                        <h4>OUR VISION</h4>
                        <p>OUR AIM, EVERYONE'S PROSPERITY </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section-padding bg-dark">
            <div className="section-header text-center section-border">
                <h2>Our Core Values</h2>
            </div> 
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 qservices">
                            <figure>
                                <img src={Integrity} height="70" width="70" alt="" />
                            </figure>
                            <h3>CARE</h3>
                            <p>We care and Maintain the Trust of People Associated with us.</p>
                        </div>
                        <div className="col-md-6 col-lg-4 qservices">
                            <figure>
                                <img src={Candor} height="70" width="70" alt="" />
                            </figure>
                            <h3>RESPONSIBILITY</h3>
                            <p>We Encourage people for doing Right things in Favour of Nature and Humanity.</p>
                        </div>
                        <div className="col-md-6 col-lg-4 qservices">
                            <figure>
                                <img src={Service} height="70" width="70" alt="" />
                            </figure>
                            <h3>INNOVATION</h3>
                            <p>We Encourage people for doing Right things in Favour of Nature and Humanity.</p>
                        </div>
                        <div className="col-md-6 col-lg-4 qservices">
                            <figure>
                                <img src={Kindness} height="70" width="70" alt="" />
                            </figure>
                            <h3>TRANSPARENCY</h3>
                            <p>Transparency is the reflection of Entvickler.</p>
                        </div>
                        <div className="col-md-6 col-lg-4 qservices">
                            <figure>
                                <img src={Competence} height="70" width="70" alt="" />
                            </figure>
                            <h3>COMMITMENT</h3>
                            <p>We Stand for our promises and Quality Services.</p>
                        </div>
                        <div className="col-md-6 col-lg-4 qservices">
                            <figure>
                                <img src={Growth} height="70" width="70" alt="" />
                            </figure>
                            <h3>GROWTH</h3>
                            <p>We stand for constant growth for all.</p>
                        </div>
                    </div>
                    
                        {/* <ul className="row core-val">
                        <li className="col-sm-6"><figure><img src={Integrity} alt=""/></figure><span className="p-3 font_header">CARE</span><span className="p-3 font_8"><p>We care and Maintain the Trust of People Associated with us.</p></span></li>
                        <li className="col-sm-6"><figure><img src={Candor} alt=""/></figure><span className="p-3 font_header">RESPONSIBILITY</span><span className="p-3 font_8"><p>We Encourage people for doing Right things in Favour of Nature and Humanity.</p></span></li>
                        <li className="col-sm-6"><figure><img src={Service} alt=""/></figure><span className="p-3 font_header">INNOVATION</span><span className="p-3 font_8"><p>We Encourage people for doing Right things in Favour of Nature and Humanity.</p></span></li>
                        <li className="col-sm-6"><figure><img src={Kindness} alt=""/></figure><span className="p-3 font_header">TRANSPARENCY</span><span className="p-3 font_8"><p>Transparency is the reflection of Entvickler.</p></span></li>
                        <li className="col-sm-6"><figure><img src={Competence} alt=""/></figure><span className="p-3 font_header">COMMITMENT</span><span className="p-3 font_8"><p>We Stand for our promises and Quality Services.</p></span></li>
                        <li className="col-sm-6"><figure><img src={Growth} alt=""/></figure><span className="p-3 font_header">Growth</span><span className="p-3 font_8"><p>We stand for constant growth for all.</p></span></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </section>
        <div data-is-absolute-layout="false" className="layout-kaxjn3t5-item layout-kaxjn3t5-component style-kaxjn3t61"
            id="comp-kaxjn3qu">
            <div id="comp-kaxjn3qubg" className="style-kaxjn3t61bg"></div>
            <div className="layout-kaxjn3t5-container style-kaxjn3t61inlineContent" id="comp-kaxjn3quinlineContent">
                <div data-is-absolute-layout="false"
                    className="layout-kb0p1nat-item layout-kb0p1nat-component style-kb0p1nau2" id="comp-kb0p1n8f">
                    <div id="comp-kb0p1n8fbg" className="style-kb0p1nau2bg"></div>
                    <div className="layout-kb0p1nat-container style-kb0p1nau2inlineContent" id="comp-kb0p1n8finlineContent">
                        <div 
                            className="layout-kaxjn3tc2-item layout-kaxjn3tc2-component style-kaxjn3td1 animating-screenIn"
                            id="comp-kaxjn3tb2">
                            <h2 className="font_0" >WHO WE ARE</h2>
                        </div>
                        <div 
                            className="layout-kaxjn3ta-item layout-kaxjn3ta-component style-kaxjn3tb1">
                                <p className="medium-text">Meet Our Exceptionaly Talented Team of Technology Geeks</p>
                            {/* <p className="font_5" >Our Creative Founders</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
  <section data-is-absolute-layout="false" className="layout-kaw8qvq0-item layout-kaw8qvq0-component style-kaw8qvq51" id="comp-kaw8qvop">
              <div id="comp-kaw8qvopbg" className="style-kaw8qvq51bg"></div>
              <div className="layout-kaw8qvq0-container style-kaw8qvq51inlineContent" id="comp-kaw8qvopinlineContent">
                <div className="layout-kbg7rnpw-item layout-kbg7rnpw-component style-kbg7rnpx" id="comp-kbg7rne8">
                  <div id="comp-kbg7rne8link" data-hook="ImageX-core-component" className="imagex3474482427__root style-kbg7rnpx">
                  <img id='Team' src={Team} alt='Team' className="fullwidth" />
                  </div>
                </div>
              </div>
          </section>
  </div>
  
);
export default MainContent;