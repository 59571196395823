import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type Route = {
  readonly path: string;
  readonly icon?: IconProp;
  readonly exact?: boolean;
  readonly displayName: string;
  readonly showInNav?: boolean;
  readonly pathAbsolute?: string;
};

export const RoutesConfig = Object.freeze<Record<string, Route>>({
  Dashboard: {
    exact: true,
    showInNav: true,
    path: '/',
    displayName: 'Home',
  },
  Products: {
    exact: true,
    path: '#products',
    showInNav: true,
    displayName: 'Products',
  },
  
  Services: {
    exact: true,
    showInNav: true,
    path: '#Services',
    displayName: 'Services',
  },
  AboutUs: {
    showInNav: true,
    exact: true,
    path: '#aboutus',
    displayName: 'About Us',
    
  },
  ContactUs: {
    showInNav: true,
    exact: true,
    path: '#contactus',
    displayName: 'Contact Us',
  },
});
