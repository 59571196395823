import React, { Fragment,useState,useRef,useCallback,useEffect } from 'react';
import { Footer, Settings } from './components';
import Navbar2 from './components/Navbar2';
import Careers from './components/Careers';
import MainContent from './components/MainContent';
import styled, { keyframes } from 'styled-components';
import Scrollchor from 'react-scrollchor';
import 'react-floating-button-menu/dist/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faBars, faGrinTears, faHome, faIdBadge } from '@fortawesome/free-solid-svg-icons';
import { faGetPocket, faProductHunt, faServicestack, faTeamspeak } from '@fortawesome/free-brands-svg-icons';
import ReactTooltip from 'react-tooltip';

const FADE_IN_KEYFRAMES = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

const CogIcon = styled(FontAwesomeIcon)`
  color: #fff;
  padding: 10px;
`;

const SettingsLink = styled.a`
  border: 0;
  outline: 0;
  cursor: pointer;
  display:block;
  padding: 1.1rem .4rem;
  background: transparent;
`;

const SettingsMenuLink = styled.a`
  width: 100%;
  color: #555;
  font-size: 1rem;
  z-index: initial;
  text-align: center;
  line-height: 0;
  position: relative;
  white-space: nowrap;
  display: inline-block;
  padding: 0;
  pointer-events: visible;
  svg {
    opacity: 0.8;
    margin: 0 0.3rem;
  }
`;

const SettingsMenuTitle = styled.li`
  color: #7f888f !important;
  font-size: 18px;
  font-weight: 600;
  margin-left: auto;
  line-height: 5px;
  margin-right: auto;
  text-align: center;
  padding-bottom: 3px;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  border-radius:25%;
  width:50%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  opacity: 0;
  transform: rotateX(-90deg);
  transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
`;

const SettingsMenu = styled.ul`
  top: 10;
  opacity: 1;
  left: auto;
  right: 0px;
  width: 5rem;
  z-index: 1000;
  display: block;
  padding: 5px 0;
  min-width: 4rem;
  user-select: none;
  position: absolute;
  background-color: transparent;
  border-radius: 0.25rem;
  box-shadow: none;

  :before,
  :after {
    top: 22px;
    content: '';
    width: 17px;
    position: absolute;
    display: inline-block;
    transform: translateY(-50%);
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
  }

  > li:not(:first-of-type) {
    transition: background-color 1s ease-out;

    :hover {
      background-color: transparent;
      color:#fff !important;
    }
  }
`;

// Before and after 
// :before {
//   left: auto;
//   right: -16px;
//   margin-left: auto;
//   border-left:16px solid #55504d;
// }

// :after {
//   right: -15px;
//   border-left: 16px solid #55504d;
// }

const StyledSettings = styled.div<{ isMenuOpen: boolean }>`
  right: 1%;
  top: 3%;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50px;
  z-index: 1031;
  position: fixed;
  text-align: center;
  animation-delay: 0.75s;
  transition: background 0.15s ease-in;
  animation: 0.75s both ease;
  background: ${({ isMenuOpen }) => isMenuOpen ? 'rgba(0,0,0,0.6)' : 'rgba(0,0,0,0.45)'};

  :hover {
    background: rgba(0, 0, 0, 0.6);
  }
`;
const Layout: React.FC = ({

 }) => {
  const settingsLinkRef = useRef<HTMLAnchorElement | null>(null);
  const [isMenuOpen, setisMenuOpen] = useState<boolean>(false);
  const onMenuClickOutside = useCallback((): void => setisMenuOpen(false), []);
   return(
     
<div>
  <Fragment>
      <Navbar2 />
      <MainContent></MainContent>
      <Careers></Careers>
      <Footer/>
  </Fragment>
  
  <StyledSettings isMenuOpen={isMenuOpen}>
  <SettingsLink role='button' ref={settingsLinkRef} onClick={() => setisMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen)} >
    <FontAwesomeIcon icon={faBars} size = '2x' color="white"/>
  </SettingsLink>
  {isMenuOpen && (
    <SettingsMenu className="delta">
      <li>
        <SettingsMenuLink className="delta-settings swing">
          <Scrollchor to="#top" animate={{offset: 10, duration: 700}} className="delta-nav-link" data-tip="Home"><FontAwesomeIcon icon={faHome} size = '2x' color="white" className="set-icon-paddings"/></Scrollchor>
          <ReactTooltip />
        </SettingsMenuLink>
      </li>
      <li>
        <SettingsMenuLink className="delta-settings swing">
          <Scrollchor to="#company" animate={{offset: 10, duration: 700}} className="delta-nav-link" data-tip="Company"><FontAwesomeIcon icon={faAddressBook} size = '2x' color="white" className="set-icon-paddings"/></Scrollchor>
          <ReactTooltip />
        </SettingsMenuLink>
      </li>
      <li>
        <SettingsMenuLink className="delta-settings">
          <Scrollchor to="#services" animate={{offset: 10, duration: 700}} className="delta-nav-link" data-tip="Services"><FontAwesomeIcon icon={faServicestack} size = '2x' color="white" className="set-icon-paddings"/></Scrollchor>
          <ReactTooltip />
        </SettingsMenuLink>
      </li>
      <li>
        <SettingsMenuLink className="delta-settings">
          <Scrollchor to="#products" animate={{offset: 10, duration: 700}} className="delta-nav-link" data-tip="Products"><FontAwesomeIcon icon={faProductHunt} size = '2x' color="white"/></Scrollchor>
          <ReactTooltip />
        </SettingsMenuLink>
      </li>
      <li>
        <SettingsMenuLink className="delta-settings">
          <Scrollchor to="#team" animate={{offset: 10, duration: 700}} className="delta-nav-link" data-tip="Team"><FontAwesomeIcon icon={faTeamspeak} size = '2x' color="white"/></Scrollchor>
          <ReactTooltip />
        </SettingsMenuLink>
      </li>
      <li>
        <SettingsMenuLink className="delta-settings">
          <Scrollchor to="#careers" animate={{offset: 10, duration: 700}} className="delta-nav-link" data-tip="Careers"><FontAwesomeIcon icon={faGetPocket} size = '2x' color="white"/></Scrollchor>
          <ReactTooltip />
        </SettingsMenuLink>
      </li>
      <li>
        <SettingsMenuLink className="delta-settings">
          <Scrollchor to="#contactus" animate={{offset: 10, duration: 700}} className="delta-nav-link" data-tip="Contact Us"><FontAwesomeIcon icon={faIdBadge} size = '2x' color="white"/></Scrollchor>
          <ReactTooltip />
        </SettingsMenuLink>
      </li>
    </SettingsMenu>
  )}
</StyledSettings>
</div>
  );
 };

export default Layout;