import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-bootstrap/Carousel';
const seospecialist=require("../assets/image/Hiring/SEO.png") as string;
const uiuxdesigner=require("../assets/image/Hiring/UX.png") as string;
const digitalmarketing=require("../assets/image/Hiring/DM.png") as string;
const businessanalyst=require("../assets/image/Hiring/BA.png") as string;
const contentwriter=require("../assets/image/Hiring/CW.png") as string;

const careergrowth=require('../assets/image/Work/careergrowth.svg') as string;
const employeebenefits=require('../assets/image/Work/employeebenefits.svg') as string;
const theculture=require('../assets/image/Work/theculture.svg') as string;

const Careers: React.FC = () => (
<div className="container-fluid m-0 padding-zero" id="careers">
<div data-is-absolute-layout="false" className="layout-kawghdc21-item" id="comp-kawghd9t">
            <div id="comp-kawghd9tbg" className="style-kawghdc4bg"></div>
            <div className="layout-kawghdc21-container style-kawghdc4inlineContent" id="comp-kawghd9tinlineContent">
                <div data-is-absolute-layout="false" className="layout-kazdnuaf-item layout-kazdnuaf-component style-kazdnuag1" id="comp-kazdnu3q">
                    <div id="comp-kazdnu3qbg" className="style-kazdnuag1bg"></div>
                    <div className="layout-kazdnuaf-container style-kazdnuag1inlineContent" id="comp-kazdnu3qinlineContent">
                        <div  className="layout-kaw5lvw5-item layout-kaw5lvw5-component style-kaw5lvw7 animating-screenIn" id="Company">
                        <h2 className="font_0">CAREERS</h2>
                        </div>
                        <div className="layout-kawh3ay1-item layout-kawh3ay1-component style-kawh3ay2" id="comp-kawh3aqm">
                        <p className="font_5" >Work with Entvickler.</p>
                        </div>
                    </div>
            <div className="layout-kaw821eu-container style-kaw821ev1inlineContent" id="comp-kaw821e4inlineContent">
            <div data-is-absolute-layout="false" className="layout-kb0obk0m-item layout-kb0obk0m-component style-kb0obk0o" id="comp-kb0objyd">
                <div id="comp-kb0objydbg" className="style-kb0obk0obg"></div>
                    <div className="layout-kb0obk0m-container style-kb0obk0oinlineContent" id="comp-kb0objydinlineContent">
                    <div  className="layout-kaw85rla-item layout-kaw85rla-component style-kaw85rlb1" id="comp-kaw85rj1">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="d-block w-40 pl-9">
                                <img  src={careergrowth} alt="First slide" />
                            </div>
                            <h4>Career Growth</h4>
                            <p className="font-justify">We accentuate our people to get trained on new skills and latest technologies, let them evaluate and improve their performance from time to time and help them chalk a career path for steady growth.</p>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                        <img className="d-block w-40 pl-9" src={employeebenefits} alt="First slide" />
                            <h4>Employee Benefits</h4>
                            <p className="font-justify">At Entvickler, we recognize and appreciate hard work. Our extensive employee benefit programs ensure that we have your needs covered while you take care of the company’s.</p>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                        <img className="d-block w-40 pl-9" src={theculture} alt="First slide" />
                            <h4>The Culture</h4>
                            <p className="font-justify">We are proud to offer a company culture that encourages innovation and embraces diversity. We make sure that our employees feel cared for at Entvickler.</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
<section className="section-padding border">
    <div className="mission-visions">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="section-header text-center section-border whyentvickler">
                        <h2>Why Entvickler?</h2>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 left">
                <h4>CAREER DEVELOPMENT</h4>
                <p>We accentuate our people to get trained on new skills and latest technologies, let them evaluate and improve their performance from time to time and help them chalk a career path for steady growth.</p>
                </div>
                <div className="col-sm-6 right">
                    <h4>WORK-LIFE BALANCE</h4>
                    <p>We encourage maintaining a healthy work-life balance. Group excursions and celebrations are part of our work. We provide option to work from home and foster efficiency to avoid work after office hours.</p>
                </div>
            </div>
            <div className="vh50"></div>
            <div className="row">
                <div className="col-sm-6 left">
                    <h4>WORK ENVIRONMENT</h4>
                    <p>Our work environment encompasses a mix of warm and supportive people with approachable management. We intend to become a great place to work by giving our people an environment to succeed.</p>
                </div>
                <div className="col-sm-6 right">
                    <h4>AWARDS &amp; RECOGNITIONS</h4>
                    <p>We take great pride in recognizing our people for doing a good job. We organize annual award ceremonies to appreciate and celebrate the efforts and achievements of 'Entvickler Associates'.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div className="row">
<Carousel>
  <Carousel.Item interval={1000}>
    <div className="row">
        <img
        className="d-block w-70"
        src={digitalmarketing}
        alt="First slide"
        />
    </div>
  </Carousel.Item>
  <Carousel.Item interval={500}>
  
    <img
      className="d-block w-70"
      src={businessanalyst}
      alt="Third slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-70"
      src={uiuxdesigner}
      alt="Third slide"
    />
    
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-70"
      src={seospecialist}
      alt="Third slide"
    />
    
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-70"
      src={contentwriter}
      alt="Third slide"
    />
  </Carousel.Item>
</Carousel>
</div>

</div>

       
);
export default Careers;