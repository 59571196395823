import React from 'react';
import IconSVG from './IconSVG';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { IApplicationState } from '../store';
import { RoutesConfig, Route } from '../config/routes.config';
import ResponsiveMenu from 'react-responsive-navbar';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavItem from 'react-bootstrap/NavItem';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Scrollchor from 'react-scrollchor';

const EntvicklerLogo = require('../assets/image/entvickler.png') as string;

 type NavbarProps = {
 readonly isAuthenticated: boolean;
};

const Navbar2: React.FC<NavbarProps> = ({ }) => {
  const navRoutes: Route[] = Object.keys(RoutesConfig).reduce((acc, key) => {
    const route = RoutesConfig[key];
    route.showInNav && acc.push(route);
    return acc;
  }, [] as Route[]);

  return (
<Navbar bg="light" expand="lg" id="top">
<Navbar.Brand href="#home"><img id='entvicker-logo-img' src={EntvicklerLogo} alt='entvickler-logo'  className="logo-set"/>

</Navbar.Brand>
{/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
<Navbar.Collapse id="basic-navbar-nav">
  <Nav className="ml-auto font_8 main-navigation-links">
  <NavItem><Scrollchor to="#company" animate={{offset: 10, duration: 700}} className="nav-link"><span className="main-navigation-links">COMPANY</span></Scrollchor></NavItem>
  <NavItem><Scrollchor to="#services" animate={{offset: 10, duration: 700}} className="nav-link"><span className="main-navigation-links">SERVICES</span></Scrollchor></NavItem>
  <NavItem><Scrollchor to="#products" animate={{offset: 10, duration: 700}} className="nav-link"><span className="main-navigation-links">PRODUCTS</span></Scrollchor></NavItem>
  <NavItem><Scrollchor to="#team" animate={{offset: 10, duration: 700}} className="nav-link"><span className="main-navigation-links">TEAM</span></Scrollchor></NavItem>
  <NavItem><Scrollchor to="#careers" animate={{offset: 10, duration: 700}} className="nav-link"><span className="main-navigation-links">CAREERS</span></Scrollchor></NavItem>
  <NavItem><Scrollchor to="#contactus" animate={{offset: 10, duration: 700}} className="nav-link"><span className="main-navigation-links">CONTACT US</span></Scrollchor></NavItem>
  </Nav>
</Navbar.Collapse> */}
</Navbar>
  );
};

const mapStateToProps = (state: IApplicationState) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Navbar2);
//export default Navbar;
