import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon, InlineIcon } from "@iconify/react";
import facebook from "@iconify/icons-mdi/facebook";
import twitter from "@iconify/icons-mdi/twitter";
import instagram from "@iconify/icons-mdi/instagram";
import youtube from "@iconify/icons-mdi/youtube";
import linkedin from "@iconify/icons-mdi/linkedin";
import phone from "@iconify/icons-mdi/phone";
import { Component,useEffect } from 'react';

// import faStyles from '@font-awesome/css/font-awesome.css'
// import sss from 

const BrandContent = styled.div`
  text-align: center;
  word-spacing: 0.05rem;
`;

const StyledFooter = styled.footer`
  color: #000000;
  width: 100%;
  margin: auto;
  display: block;
  font-size: 1.15rem;
  padding: 0;
  background-color: #ffd77ce6;

  @media only screen and (max-width: 769px) {
    font-size: 1rem;
  }
`;

const FooterButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0rem;
  align-items: center;
  justify-content: flex-start;
  background-color: #fadbb2;

  > a {
    :first-child {
      margin-left: auto !important;
    }

    :last-child {
      margin-right: auto !important;
    }
  }
`;

const FooterLink = styled.a`
  color: #a29292;
  margin-bottom: 0;
  font-size: 2.25rem;
  padding: .5rem;
  border-color: transparent;
  margin-right: 0 !important;
  background-color: transparent;
  transition: color 0.2s ease-out;

  &:hover {
    color: #09d3ac;
  }

  .icon {
    align-items: baseline;
  }
`;
const Services = require('../assets/image/footer.png') as string;
const all_under_one_roof=require("../assets/image/SVG/partners/all_under_one_roof.svg") as string;
const quality=require("../assets/image/SVG/partners/quality.svg") as string;
const reliability=require("../assets/image/SVG/partners/reliability.svg") as string;
const life_time_support=require("../assets/image/SVG/partners/life_time_support.svg") as string;
const innovative=require("../assets/image/SVG/partners/innovative.svg") as string;
const geeky=require("../assets/image/SVG/partners/geeky.svg") as string;
const agile	=require("../assets/image/SVG/partners/agile.svg") as string;
const always_on_time=require("../assets/image/SVG/partners/always_on_time.svg") as string;
const transparency=require("../assets/image/SVG/partners/transparency.svg") as string;
const data_security=require("../assets/image/SVG/partners/data_security.svg") as string;
const value_for_money=require("../assets/image/SVG/partners/value_for_money.svg") as string;
const iso_certified=require("../assets/image/SVG/partners/iso_certified.svg") as string;

const Footer: React.FC = ({

}) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.id    = 'tawkId';
        script.async = true;
        script.src   = 'https://embed.tawk.to/' + '5fe58bc9df060f156a9000f0/1eqcak30u';
        script.charset = 'UTF-8';
        script.setAttribute('crossorigin', '*');
        document.body.appendChild(script);
      }, []);
return(
<section>
<div className="container-fluid">
<section data-is-absolute-layout="false" className="layout-kazbcvru-item layout-kazbcvru-component style-kazbcvrw" id="comp-kazbcvr9">
    <div id="comp-kazbcvr9bg" className="style-kazbcvrwbg"></div>
    <div className="layout-kazbcvru-container style-kazbcvrwinlineContent" id="comp-kazbcvr9inlineContent">
        <div data-is-absolute-layout="false" className="layout-kazdreoj-item layout-kazdreoj-component style-kazdreol"
            id="comp-kazdreio">
            <div id="comp-kazdreiobg" className="style-kazdreolbg"></div>
            <div className="layout-kazdreoj-container style-kazdreolinlineContent" id="comp-kazdreioinlineContent">
                
                <div  className="layout-kazdpzcy-item layout-kazdpzcy-component style-kazdpzd1"
                    id="comp-kazdpz5x">
                    <h2 className="font_5" >A Better Way to Work</h2>
                </div>
                <div 
                    className="layout-kazdr7852-item layout-kazdr7852-component style-kazdr7861" id="comp-kazdr71z">
                    <p className="font_8 font-justify"><span>Together we’re building the future of work.
                   Explore how Entvickler and our partners are delivering innovative joint solutions to power a better way to work for all&nbsp;</span></p>
                </div>
                <div id="comp-kazdrxpr" data-align="center" data-disabled="false" data-margin="0"
                    data-should-use-flex="true" data-width="160" data-height="90"
                    className="layout-kazdrxwc-item layout-kazdrxwc-component style-kazdrxwc4"
                    data-state="desktop shouldUseFlex center"><a
                        href="#services" target="_self"
                        id="comp-kazdrxprlink" className="style-kazdrxwc4link"><span id="comp-kazdrxprlabel"
                            className="style-kazdrxwc4label">Company</span></a></div>
            </div>
            <section id="accor-mob" className="work-with-section section-padding">
            <div className="row">
                        <div className="col-sm-12">
                            <div className="section-header text-center section-border">
                                <h2>Why Partner with Us?</h2>
                            </div>
                        </div>
            </div>
            <div className="container">
                    <ul className="row">
                        <li className="col-sm-6 accor-cont">
                        <figure><img className="lazy" width="100" height="100" alt=""
                                        src={all_under_one_roof}  /></figure>
                            <h4>
                                <span>All Under One Roof</span>
                            </h4>
                            <p className="closedP">Our Web Technology, Mobile Technology, and Digital Marketing resources work under the
                                same roof, so you don’t need to depend on multiple companies.</p>
                        </li>
                        <li className="col-sm-6 accor-cont">
                            <h4>
                                <figure><img className="lazy" width="100" height="100" alt="" src={quality}
                                        /></figure>
                                <span>Quality</span>
                            </h4>
                            <p className="closedP">100% Quality standards and Client satisfaction are what we assure and ensure when we
                                deliver. We strive to improve our people and processes continuously.</p>
                        </li>
                    </ul>
                    <ul className="row">
                        <li className="col-sm-6 accor-cont">
                            <h4>
                                <figure><img className="lazy" width="100" height="100" alt="" src={reliability}
                                        /></figure>
                                <span>Reliable</span>
                            </h4>
                            <p className="closedP">
                                We have the trust of more than 2,000 businesses globally. You can rely on us to get your work done the
                                right way at the right time.</p>
                        </li>
                        <li className="col-sm-6 accor-cont">
                            <h4>
                                <figure><img className="lazy" width="100" height="100" alt=""
                                        src={life_time_support} /></figure>
                                <span>Life-Time Support</span>
                            </h4>
                            <p className="closedP">
                                We offer Free Life-Time Support post project delivery. This includes fixing bugs, resolving issues and
                                training on the system.</p>
                        </li>
                    </ul>
                    <ul className="row">
                        <li className="col-sm-6 accor-cont">
                            <h4>
                                <figure><img className="lazy" width="100" height="100" alt="" src={innovative}
                                        /></figure>
                                <span>Innovative</span>
                            </h4>
                            <p className="closedP">
                                Our focus is not just to deliver, but to innovate, implement new ideas and technologies and help you
                                succeed in growing your business.</p>
                        </li>
                        <li className="col-sm-6 accor-cont">
                            <h4>
                                <figure><img className="lazy" width="100" height="100" alt="" src={geeky}
                                        /></figure>
                                <span>Geeky</span>
                            </h4>
                            <p className="closedP">
                                Our talent pool is full of geeks. We can assure that your project is not with just highly skilled
                                resources, but tech lovers who love to innovate.</p>
                        </li>
                    </ul>
                    <ul className="row">
                        <li className="col-sm-6 accor-cont">
                            <h4>
                                <figure><img className="lazy" width="100" height="100" alt="" src={agile}
                                        /></figure>
                                <span>Agile</span>
                            </h4>
                            <p className="closedP">
                                We believe in the agile approach to delivering your project. We advocate adaptive planning, evolutionary
                                development, and&nbsp;continuous improvement.</p>
                        </li>
                        <li className="col-sm-6 accor-cont">
                            <h4>
                                <figure><img className="lazy" width="100" height="100" alt=""
                                        src={always_on_time} /></figure>
                                <span>Always On Time</span>
                            </h4>
                            <p className="closedP">
                                On-Time Delivery is one of our key focus areas. We always follow a proactive approach to identify and
                                mitigate issues which can impede the process.</p>
                        </li>
                    </ul>
                    <ul className="row">
                        <li className="col-sm-6 accor-cont">
                            <h4>
                                <figure><img className="lazy" width="100" height="100" alt="" src={transparency}
                                        /></figure>
                                <span>Transparency</span>
                            </h4>
                            <p className="closedP">
                                We maintain complete transparency in rendering services and solutions with regular communication, user
                                testing option for clients, and review meetings.</p>
                        </li>
                        <li className="col-sm-6 accor-cont">
                            <h4>
                                <figure><img className="lazy" width="100" height="100" alt="" src={data_security}
                                        /></figure>
                                <span>Data Security</span>
                            </h4>
                            <p className="closedP">
                                Protecting confidential information of our clients is our top priority. We keep updated with latest data
                                security best practices, compliance, and regulations.</p>
                        </li>
                    </ul>
                    <ul className="row">
                        <li className="col-sm-6 accor-cont">
                            <h4>
                                <figure><img className="lazy" width="100" height="100" alt=""
                                        src={value_for_money} /></figure>
                                <span>Value For Money</span>
                            </h4>
                            <p className="closedP">
                                With an effective cost and benefit analysis, we ensure the best value for money in terms of the services
                                and solutions we offer our clients.</p>
                        </li>
                        <li className="col-sm-6 accor-cont">
                            <h4>
                                <figure><img className="lazy" width="100" height="100" alt="" src={iso_certified}
                                        /></figure>
                                <span>ISO Certified</span>
                            </h4>
                            <p className="closedP">
                                We are an ISO 9001:2015 certified company. We have a sturdy Quality Management System and we follow a
                                process oriented approach to deliver.</p>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    </div>
  </section>
  <div className="col-md-12 padding-zero set-margins-footer">
    <img id='Services' src={Services} alt='Services' className="fullwidth tab-set" />
</div>
<section data-is-absolute-layout="false" className="layout-kaxjmobl-item layout-kaxjmobl-component" id="contactus">
    <div id="comp-kaxjmob0bg" className="style-kaxjmobvbg"></div>
    <div className="layout-kaxjmobl-container">
		<div data-is-absolute-layout="false" className="layout-kaxjn3t5-item layout-kaxjn3t5-component style-kaxjn3t61" id="comp-kaxjn3qu">
			<div id="comp-kaxjn3qubg" className="style-kaxjn3t61bg"></div>
			<div className="layout-kaxjn3t5-container style-kaxjn3t61inlineContent" id="comp-kaxjn3quinlineContent">
				<div data-is-absolute-layout="false" className="layout-kb0p1nat-item layout-kb0p1nat-component style-kb0p1nau2" id="comp-kb0p1n8f">
					<div id="comp-kb0p1n8fbg" className="style-kb0p1nau2bg"></div>
					<div className="layout-kb0p1nat-container style-kb0p1nau2inlineContent" id="comp-kb0p1n8finlineContent">
						<div className="layout-kaxjn3tc2-item layout-kaxjn3tc2-component style-kaxjn3td1 animating-screenIn" id="comp-kaxjn3tb2">
							<h2 className="font_0" >CONTACT US</h2>
						</div>
						<div 
							className="layout-kaxjn3ta-item layout-kaxjn3ta-component style-kaxjn3tb1">
							<p className="font_8" >Let us connect here</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<div className="container-fluid" id="aboutus">
	<div className="row">
		<div className="col-sm-6 col-md-6 col-lg-3">
		<div data-is-absolute-layout="false" className="comp-k5cfsti12_r_comp-kazefiia-layoutQuery-item comp-k5cfsti12_r_comp-kazefiia-layoutQuery-component style-kazefikv1" id="comp-k5cfsti12_r_comp-kazefiia">
			   
			   {/*<div id="comp-k5cfsti12_r_comp-kazefiiabg" className="style-kazefikv1bg"></div>*/}
			   {/*<div className="comp-k5cfsti12_r_comp-kazefiia-layoutQuery-container style-kazefikv1inlineContent" id="comp-k5cfsti12_r_comp-kazefiiainlineContent">*/}
				  {/*<div  className="comp-k5cfsti12_r_comp-kazee9gs-layoutQuery-item comp-k5cfsti12_r_comp-kazee9gs-layoutQuery-component style-kbum7k2w" id="comp-k5cfsti12_r_comp-kazee9gs">*/}
					 {/*<h2 className="font_0 bold-text">UAE</h2>*/}
				  {/*</div>*/}
				 
				  {/*<div  className="comp-k5cfsti12_r_comp-kazebwd5-layoutQuery-item comp-k5cfsti12_r_comp-kazebwd5-layoutQuery-component style-kbera2fm" id="comp-k5cfsti12_r_comp-kazebwd5">*/}
					 {/*<p className="font_7">*/}
      {/*               <p className="margin-top-half">ENTVICKLER SYSTEMS LLC,</p>*/}
      {/*               <p className="margin-top-half">#17, UNIVERSAL DMCC, DUBAI INVESTMENT PARK 1,</p> */}
      {/*               <p className="margin-top-half">DUBAI, UAE - 111485</p>*/}
					 {/*</p>*/}
      {/*               <p><span><Icon icon={phone} width="20" height="20"/>OFFICE- (UAE) +971-569908131</span></p>*/}
				  {/*</div>*/}
			   {/*</div>*/}
			</div>
		</div>
		<div className="col-sm-6 col-md-6 col-lg-4">
		<div  className="comp-k5cfsti12_r_comp-kb0m8649-layoutQuery-item comp-k5cfsti12_r_comp-kb0m8649-layoutQuery-component style-kbuqe4jd" id="comp-k5cfsti12_r_comp-kb0m8649">
		   		
				   <div><p className="bold-text">INDIA</p></div>
				   <div  className="comp-k5cfsti12_r_comp-kazebwd5-layoutQuery-item comp-k5cfsti12_r_comp-kazebwd5-layoutQuery-component style-kbera2fm" id="comp-k5cfsti12_r_comp-kazebwd5">
					<p className="font_7">
					<p className="margin-top-half">ENTVICKLER SYSTEMS LLP,</p>
					<p className="margin-top-half">NO. 6/858-M, 2ND FLOOR, SUITE NO. 897</p>
					<p className="margin-top-half">VALAMKOTTIL TOWERS,INFOPARK EXPRESS WAY,</p>
                    <p className="margin-top-half">KAKKANAD, KOCHI,KERALA, INDIA - 682021</p>
                    <p className="margin-top-half"><span><Icon icon={phone} width="20" height="20"/>OFFICE- (IN) 0484-4069257</span></p></p>
				 </div>
		   </div>
		   
		</div>
		<div className="col-sm-6 col-md-6 col-lg-1">
		<div data-is-absolute-layout="false" className="comp-k5cfsti12_r_comp-kazefyao-layoutQuery-item comp-k5cfsti12_r_comp-kazefyao-layoutQuery-component style-kazefydk1" id="comp-k5cfsti12_r_comp-kazefyao">
			  <div id="comp-k5cfsti12_r_comp-kazefyaobg" className="style-kazefydk1bg"></div>
			  <div className="comp-k5cfsti12_r_comp-kazefyao-layoutQuery-container style-kazefydk1inlineContent" id="comp-k5cfsti12_r_comp-kazefyaoinlineContent">
				 <div  className="comp-k5cfsti12_r_comp-kazefq9i-layoutQuery-item comp-k5cfsti12_r_comp-kazefq9i-layoutQuery-component style-kbum7faj" id="comp-k5cfsti12_r_comp-kazefq9i">
					<p className="font_0 bold-text">SOCIALS</p>
				 </div>
				 <div  className="comp-k5cfsti12_r_comp-kazedhi7-layoutQuery-item comp-k5cfsti12_r_comp-kazedhi7-layoutQuery-component style-kberac1o" id="comp-k5cfsti12_r_comp-kazedhi7">
					<p className="font_7 margin-zero"><a href="https://www.facebook.com/entvickler" target="_blank" data-content="https://www.facebook.com/entvickler" data-type="external" rel="nofollow noopener">Facebook</a></p>
					<p className="font_7 margin-zero"><a href="https://www.instagram.com/entvickler" target="_blank" data-content="https://www.instagram.com/entvickler" data-type="external" rel="nofollow noopener">Instagram</a></p>
					<p className="font_7 margin-zero"><a href="https://twitter.com/entvickler" target="_blank" data-content="https://twitter.com/entvickler" data-type="external" rel="nofollow noopener">Twitter</a></p>
					<p className="font_7 margin-zero"><a href="https://www.linkedin.com/company/entvickler" target="_blank" data-content="https://www.linkedin.com/company/entvickler" data-type="external" rel="nofollow noopener">LinkedIn</a></p>
					<p className="font_7 margin-zero"><a href="https://youtube.com/channel/UCa0WEI-YfuOVg_QIbkshJaw" target="_blank" data-content="https://youtube.com/channel/UCa0WEI-YfuOVg_QIbkshJaw" data-type="external" rel="nofollow noopener">Youtube</a></p>
				 </div>
			  </div>
		   </div>
		</div>
		<div className="col-sm-6 col-md-6 col-lg-4">
		<div data-is-absolute-layout="false" className="comp-k5cfsti12_r_comp-kb0ecttj-layoutQuery-item comp-k5cfsti12_r_comp-kb0ecttj-layoutQuery-component appWidget1" id="comp-k5cfsti12_r_comp-kb0ecttj">
			  <div className="comp-k5cfsti12_r_comp-kb0ecttj-layoutQuery-container appWidget1inlineContent" id="comp-k5cfsti12_r_comp-kb0ecttjinlineContent">
				 <div data-is-responsive="true" className="comp-k5cfsti12_r_comp-kb0ecttx-layoutQuery-item comp-k5cfsti12_r_comp-kb0ecttx-layoutQuery-component style-kbtirru4" id="comp-k5cfsti12_r_comp-kb0ecttx">
					<div className="comp-k5cfsti12_r_comp-kb0ecttx-layoutQuery-container style-kbtirru4form" id="comp-k5cfsti12_r_comp-kb0ecttxform">
					   <div  className="comp-k5cfsti12_r_comp-kb0m1nyp-layoutQuery-item comp-k5cfsti12_r_comp-kb0m1nyp-layoutQuery-component style-kbum7bs2" id="comp-k5cfsti12_r_comp-kb0m1nyp">
						  <h2 className="font_0 bold-text">STAY CONNECTED</h2>
					   </div>
					   <div  className="comp-k5cfsti12_r_comp-kb0m4nu3-layoutQuery-item comp-k5cfsti12_r_comp-kb0m4nu3-layoutQuery-component style-kberantk" id="comp-k5cfsti12_r_comp-kb0m4nu3">
						  <p className="font_7">Stay up-to date with the latest news and updates</p>
					   </div>
					   <div className="style-kbummavu_left-direction comp-k5cfsti12_r_comp-kb0ectuc1-layoutQuery-item comp-k5cfsti12_r_comp-kb0ectuc1-layoutQuery-component style-kbummavu" data-disabled="false" id="comp-k5cfsti12_r_comp-kb0ectuc1"><input type="email"  value="" placeholder="info@entvickler.com" pattern="^.+@.+\.[a-zA-Z]{2,63}$"  className="has-custom-focus style-kbummavuinput"  id="comp-k5cfsti12_r_comp-kb0ectuc1input"/></div>
					   <div id="comp-k5cfsti12_r_comp-kb0ectu5" data-align="center" data-disabled="false" data-margin="0" data-should-use-flex="true" data-width="160" data-height="90"  className="comp-k5cfsti12_r_comp-kb0ectu5-layoutQuery-item comp-k5cfsti12_r_comp-kb0ectu5-layoutQuery-component style-kb0ectu81" data-state="desktop shouldUseFlex center"><button id="comp-k5cfsti12_r_comp-kb0ectu5link" className="style-kb0ectu81link" ><span id="comp-k5cfsti12_r_comp-kb0ectu5label" className="style-kb0ectu81label">Submit</span></button></div>
					   <div  data-hidden="true" className="comp-k5cfsti12_r_comp-kb0ectu91-layoutQuery-item comp-k5cfsti12_r_comp-kb0ectu91-layoutQuery-component style-kb7jzel6" id="comp-k5cfsti12_r_comp-kb0ectu91">
						  <p className="font_9">Thanks for submitting!</p>
					   </div>
				 </div>
				 </div>
			  </div>
		   </div>
		</div>
	</div>
</div>
</div>
<StyledFooter>
<div className="container">
    <BrandContent>For General Enquires drop an email to <a href="mailto:info@entvickler.com">info@entvickler.com</a></BrandContent>
    <FooterButtons>
        <FooterLink href='https://www.facebook.com/entvickler' aria-label='facebook'>
            <Icon icon={facebook} />
        </FooterLink>
        <FooterLink href='https://twitter.com/entvickler' aria-label='twitter'>
            <Icon icon={twitter} />
        </FooterLink>
        <FooterLink href='https://www.linkedin.com/company/entvickler' aria-label='linkedin'>
            <Icon icon={linkedin} />
        </FooterLink>
        <FooterLink href='https://youtube.com/channel/UCa0WEI-YfuOVg_QIbkshJaw' aria-label='youtube'>	
            <Icon icon={youtube} />
        </FooterLink>
        <FooterLink href='https://www.instagram.com/entvickler' aria-label='instagram'>	
            <Icon icon={instagram} />
        </FooterLink>
    </FooterButtons>
    <BrandContent id="/products">Entvickler Systems LLP &copy; 2020</BrandContent>
	<BrandContent>Powered By Entvickler Development Team</BrandContent>
</div>
    
  </StyledFooter>
</section>
);
};

export default Footer;