import React, { useEffect } from 'react';
import Layout from './Layout';
import { History } from 'history';
import { Route, Switch } from 'react-router';
import { SignalRApi } from './api/signalR.service';
import { RoutesConfig } from './config/routes.config';
import { ConnectedRouter } from 'connected-react-router';
import { Dashboard, FetchData, Form, Login } from './containers';


const App: React.FC<{ history: History }> = ({ history }) => {
  useEffect(() => {
    SignalRApi.startConnection();
  }, []);

  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Switch>
          <Route path="/" component={App} />
          <Route exact path={RoutesConfig.Dashboard.path} component={Dashboard} />
        </Switch>
      </Layout>
    </ConnectedRouter>
  );
};

export default App;